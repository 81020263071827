import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import {
  setAccessToken,
  removeAccessToken,
  setRefreshToken,
  removeRefreshToken,
  getAccessToken,
  getRefreshToken,
  refreshAccessToken
} from "../utils/auth";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUser = () => {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();
      if (accessToken && refreshToken) {
        const userId = localStorage.getItem("userId");
        const userEmail = localStorage.getItem("userEmail");
        const authenticated = localStorage.getItem("authenticated") === "true";
        setUser({
          id: userId,
          email: userEmail,
          accessToken,
          refreshToken,
          authenticated,
        });
        console.log('AuthProvider - User loaded from storage:', { userId, userEmail, authenticated });
      }
      setLoading(false);
    };

    loadUser();
  }, []);

  const login = useCallback((accessToken, refreshToken, userId, email, authenticated) => {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    localStorage.setItem("userId", userId);
    localStorage.setItem("userEmail", email);
    localStorage.setItem("authenticated", authenticated);
    setUser({ id: userId, email, accessToken, refreshToken, authenticated });
    console.log('AuthProvider - User logged in:', { userId, email, authenticated });
  }, []);

  const logout = useCallback(() => {
    removeAccessToken();
    removeRefreshToken();
    localStorage.removeItem("userId");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("authenticated");
    setUser(null);
    console.log('AuthProvider - User logged out');
  }, []);

  const updateAccessToken = useCallback((newToken) => {
    setAccessToken(newToken);
    setUser(prevUser => ({
      ...prevUser,
      accessToken: newToken
    }));
    console.log('AuthProvider - Access token updated');
  }, []);

  const refreshToken = useCallback(async () => {
    const currentRefreshToken = getRefreshToken();
    if (currentRefreshToken) {
      try {
        const newAccessToken = await refreshAccessToken(currentRefreshToken);
        if (newAccessToken) {
          updateAccessToken(newAccessToken);
          return newAccessToken;
        }
      } catch (error) {
        console.error('Failed to refresh token:', error);
        logout();
      }
    }
    return null;
  }, [updateAccessToken, logout]);

  const value = {
    user,
    login,
    logout,
    loading,
    updateAccessToken,
    refreshToken
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};