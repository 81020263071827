import React, { useState } from 'react';
import { EnvelopeIcon, ChatBubbleLeftRightIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

const ContactPage = () => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [reason, setReason] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');
  
    try {
      const response = await fetch('/.netlify/functions/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          subject,
          message,
          reason,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      setStatus('success');
      setEmail('');
      setSubject('');
      setMessage('');
      setReason('');
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setStatus('error');
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-extrabold text-gray-900 mb-4">Get in Touch</h1>
          <p className="text-xl text-gray-600">We're here to help and answer any question you might have.</p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div>
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Contact Information</h2>
            <ul className="space-y-6">
              <ContactItem icon={EnvelopeIcon} title="Email" content="support@vizify.xyz" />
              <ContactItem 
                icon={ChatBubbleLeftRightIcon} 
                title="Social Media" 
                content={<a href="https://x.com/vizifydotxyz" className="text-blue-600 hover:underline">Follow on X</a>} 
              />
            </ul>

            {/* Refund Policy Section */}
            <div className="bg-blue-50 p-4 rounded-md mt-8">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Photo Credit Refund Policy</h3>
              <p className="text-gray-600 mb-2">
                If a generated photo contains clear visual issues (such as distortions or artifacts), you can request a refund. Simply send the problematic photo via a DM on X or email it to us at <a href="mailto:support@vizify.xyz" className="text-blue-600 hover:underline">support@vizify.xyz</a>.
              </p>
              <p className="text-gray-600">
                If the photo has an obvious issue, we will refund the used photo credit and give you two additional credits. If there are no clear issues, we reserve the right to deny the refund request.
              </p>
              <p className="text-gray-600">
                We appreciate your understanding and will do our best to ensure you have the best experience!
              </p>
            </div>
          </div>

          <div>
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Send Us a Message</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <FormField
                id="reason"
                label="Reason for Contact"
                type="select"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                required
              >
                <option value="">Select a reason</option>
                <option value="general">General Inquiry</option>
                <option value="book-request">Generation Results</option>
                <option value="support">Technical Support</option>
                <option value="feedback">Feedback</option>
              </FormField>

              <FormField
                id="email"
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <FormField
                id="subject"
                label="Subject"
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />

              <FormField
                id="message"
                label="Message"
                type="textarea"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150"
                >
                  Send Message
                </button>
              </div>
            </form>

            {status && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className={`mt-4 p-4 rounded-md ${
                  status === 'success' ? 'bg-green-50' : status === 'error' ? 'bg-red-50' : 'bg-blue-50'
                }`}
              >
                {status === 'success' && (
                  <div className="flex items-center">
                    <CheckCircleIcon className="h-5 w-5 text-green-400 mr-2" />
                    <p className="text-green-800">Message sent successfully!</p>
                  </div>
                )}
                {status === 'error' && (
                  <div className="flex items-center">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-400 mr-2" />
                    <p className="text-red-800">Failed to send message. Please try again.</p>
                  </div>
                )}
                {status === 'sending' && (
                  <div className="flex items-center">
                    <svg className="animate-spin h-5 w-5 text-blue-500 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <p className="text-blue-800">Sending message...</p>
                  </div>
                )}
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactItem = ({ icon: Icon, title, content }) => (
  <li className="flex items-start">
    <div className="flex-shrink-0">
      <Icon className="h-6 w-6 text-blue-500" />
    </div>
    <div className="ml-3">
      <p className="text-sm font-medium text-gray-900">{title}</p>
      <p className="text-sm text-gray-500">{content}</p>
    </div>
  </li>
);

const FormField = ({ id, label, type, value, onChange, required, children }) => (
  <div>
    <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
      {label}
    </label>
    <div>
      {type === 'textarea' ? (
        <textarea
          id={id}
          value={value}
          onChange={onChange}
          required={required}
          rows={4}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      ) : type === 'select' ? (
        <select
          id={id}
          value={value}
          onChange={onChange}
          required={required}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
        >
          {children}
        </select>
      ) : (
        <input
          type={type}
          id={id}
          value={value}
          onChange={onChange}
          required={required}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      )}
    </div>
  </div>
);

export default ContactPage;


// import React, { useState } from 'react';
// import { EnvelopeIcon, ChatBubbleLeftRightIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
// import { motion } from 'framer-motion';

// const ContactPage = () => {
//   const [email, setEmail] = useState('');
//   const [subject, setSubject] = useState('');
//   const [message, setMessage] = useState('');
//   const [reason, setReason] = useState('');
//   const [status, setStatus] = useState('');

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setStatus('sending');
  
//     try {
//       const response = await fetch('/.netlify/functions/send-email', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           email,
//           subject,
//           message,
//           reason,
//         }),
//       });
  
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
  
//       setStatus('success');
//       setEmail('');
//       setSubject('');
//       setMessage('');
//       setReason('');
//     } catch (error) {
//       console.error('There was a problem with the fetch operation:', error);
//       setStatus('error');
//     }
//   };
  

//   return (
//     <div className="bg-gray-50 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
//       <div className="max-w-4xl mx-auto">
//         <motion.div
//           initial={{ opacity: 0, y: -20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.5 }}
//           className="text-center mb-12"
//         >
//           <h1 className="text-4xl font-extrabold text-gray-900 mb-4">Get in Touch</h1>
//           <p className="text-xl text-gray-600">We're here to help and answer any question you might have.</p>
//         </motion.div>

//         <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
//           <div>
//             <h2 className="text-2xl font-bold text-gray-900 mb-6">Contact Information</h2>
//             <ul className="space-y-6">
//               <ContactItem icon={EnvelopeIcon} title="Email" content="support@vizify.xyz" />
//               <ContactItem 
//                 icon={ChatBubbleLeftRightIcon} 
//                 title="Social Media" 
//                 content={<a href="https://x.com/vizifydotxyz" className="text-blue-600 hover:underline">Follow on X</a>} 
//               />
//             </ul>
//           </div>

//           <div>
//             <h2 className="text-2xl font-bold text-gray-900 mb-6">Send Us a Message</h2>
//             <form onSubmit={handleSubmit} className="space-y-6">
//               <FormField
//                 id="reason"
//                 label="Reason for Contact"
//                 type="select"
//                 value={reason}
//                 onChange={(e) => setReason(e.target.value)}
//                 required
//               >
//                 <option value="">Select a reason</option>
//                 <option value="general">General Inquiry</option>
//                 <option value="book-request">Generation Results</option>
//                 <option value="support">Technical Support</option>
//                 <option value="feedback">Feedback</option>
//               </FormField>

//               <FormField
//                 id="email"
//                 label="Email"
//                 type="email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 required
//               />

//               <FormField
//                 id="subject"
//                 label="Subject"
//                 type="text"
//                 value={subject}
//                 onChange={(e) => setSubject(e.target.value)}
//                 required
//               />

//               <FormField
//                 id="message"
//                 label="Message"
//                 type="textarea"
//                 value={message}
//                 onChange={(e) => setMessage(e.target.value)}
//                 required
//               />

//               <div>
//                 <button
//                   type="submit"
//                   className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150"
//                 >
//                   Send Message
//                 </button>
//               </div>
//             </form>

//             {status && (
//               <motion.div
//                 initial={{ opacity: 0, y: 20 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.3 }}
//                 className={`mt-4 p-4 rounded-md ${
//                   status === 'success' ? 'bg-green-50' : status === 'error' ? 'bg-red-50' : 'bg-blue-50'
//                 }`}
//               >
//                 {status === 'success' && (
//                   <div className="flex items-center">
//                     <CheckCircleIcon className="h-5 w-5 text-green-400 mr-2" />
//                     <p className="text-green-800">Message sent successfully!</p>
//                   </div>
//                 )}
//                 {status === 'error' && (
//                   <div className="flex items-center">
//                     <ExclamationCircleIcon className="h-5 w-5 text-red-400 mr-2" />
//                     <p className="text-red-800">Failed to send message. Please try again.</p>
//                   </div>
//                 )}
//                 {status === 'sending' && (
//                   <div className="flex items-center">
//                     <svg className="animate-spin h-5 w-5 text-blue-500 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
//                       <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
//                       <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
//                     </svg>
//                     <p className="text-blue-800">Sending message...</p>
//                   </div>
//                 )}
//               </motion.div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const ContactItem = ({ icon: Icon, title, content }) => (
//   <li className="flex items-start">
//     <div className="flex-shrink-0">
//       <Icon className="h-6 w-6 text-blue-500" />
//     </div>
//     <div className="ml-3">
//       <p className="text-sm font-medium text-gray-900">{title}</p>
//       <p className="text-sm text-gray-500">{content}</p>
//     </div>
//   </li>
// );

// const FormField = ({ id, label, type, value, onChange, required, children }) => (
//   <div>
//     <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
//       {label}
//     </label>
//     <div>
//       {type === 'textarea' ? (
//         <textarea
//           id={id}
//           value={value}
//           onChange={onChange}
//           required={required}
//           rows={4}
//           className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
//         />
//       ) : type === 'select' ? (
//         <select
//           id={id}
//           value={value}
//           onChange={onChange}
//           required={required}
//           className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
//         >
//           {children}
//         </select>
//       ) : (
//         <input
//           type={type}
//           id={id}
//           value={value}
//           onChange={onChange}
//           required={required}
//           className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
//         />
//       )}
//     </div>
//   </div>
// );

// export default ContactPage;
