import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../pages/cropImage";
import usePhotoApi from "../services/photoService";
import { PhotoIcon, CloudArrowUpIcon } from "@heroicons/react/24/outline";


const ALLOWED_ASPECT_RATIOS = [
  { name: "1:1", value: 1 },
  { name: "3:4", value: 3 / 4 },
  { name: "2:3", value: 2 / 3 },
  { name: "9:16", value: 9 / 16 },
  { name: "1:2", value: 1 / 2 },
];

const AGE_CATEGORIES = [
  "toddler",
  "young child",
  "child",
  "teenager",
  "adult",
  "senior"
];

const MAX_FILE_SIZE = 10 * 1024 * 1024;

const checkAspectRatio = (width, height) => {
  const aspectRatio = width / height;
  return ALLOWED_ASPECT_RATIOS.some(
    (ratio) => Math.abs(aspectRatio - ratio.value) < 0.01
  );
};

const UploadPhotosComponent = ({ onUploadSuccess }) => {
  const { uploadPhotos } = usePhotoApi();
  const [frontalPhoto, setFrontalPhoto] = useState(null);
  const [frontalPhotoPreview, setFrontalPhotoPreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [needsCropping, setNeedsCropping] = useState(false);
  const [selectedAspectRatio, setSelectedAspectRatio] = useState(
    ALLOWED_ASPECT_RATIOS[0]
  );
  const [ageCategory, setAgeCategory] = useState("");
  const [gender, setGender] = useState("");

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        alert("File size exceeds 5 MB limit. Please choose a smaller file.");
        return;
      }
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const needsCropping = !checkAspectRatio(img.width, img.height);
        setNeedsCropping(needsCropping);

        const localUrl = URL.createObjectURL(file);
        setFrontalPhoto(file);
        setFrontalPhotoPreview(localUrl);
        setSelectedPhoto({ url: localUrl, file: file });
        setCrop({ x: 0, y: 0 });
        setZoom(1);
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (frontalPhoto && ageCategory && gender) {
      setIsUploading(true);
      try {
        const response = await uploadPhotos(frontalPhoto, ageCategory, gender);
        setFrontalPhoto(null);
        setFrontalPhotoPreview(null);
        setAgeCategory("");
        setGender("");
        onUploadSuccess(response);
      } catch (error) {
        console.error('Failed to upload photo:', error);
      } finally {
        setIsUploading(false);
      }
    } else {
      alert('Please select a photo and fill out all fields before submitting.');
    }
  };

  const handleCrop = async () => {
    setSelectedPhoto(null);
    try {
      const croppedImage = await getCroppedImg(
        selectedPhoto.url,
        croppedAreaPixels
      );
      const blob = await fetch(croppedImage).then((res) => res.blob());
      const localUrl = URL.createObjectURL(blob);
      setFrontalPhoto(blob);
      setFrontalPhotoPreview(localUrl);
      setNeedsCropping(false);
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  };

  const handleUseOriginal = () => {
    if (selectedPhoto && selectedPhoto.file) {
      if (needsCropping) {
        alert("The image aspect ratio is not optimal.");
      }
      const localUrl = URL.createObjectURL(selectedPhoto.file);
      setFrontalPhoto(selectedPhoto.file);
      setFrontalPhotoPreview(localUrl);
      setSelectedPhoto(null);
      setNeedsCropping(false);
    }
  };

  // const handleUseOriginal = async () => {
  //   if (needsCropping) {
  //     alert("The image aspect ratio is not optimal. Please crop the image.");
  //     return;
  //   }
  //   setSelectedPhoto(null);
  //   if (selectedPhoto && selectedPhoto.file) {
  //     try {
  //       const localUrl = URL.createObjectURL(selectedPhoto.file);
  //       setFrontalPhoto(selectedPhoto.file);
  //       setFrontalPhotoPreview(localUrl);
  //     } catch (error) {
  //       console.error("Error using original photo:", error);
  //     }
  //   }
  // };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white shadow-lg rounded-xl">
      <form onSubmit={handleSubmit} className="space-y-8">
        <FileUpload
          label="Frontal Photo"
          onChange={handlePhotoChange}
          preview={frontalPhotoPreview}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-700">Age Category</label>
            <select
              value={ageCategory}
              onChange={(e) => setAgeCategory(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            >
              <option value="" disabled>Select age category</option>
              {AGE_CATEGORIES.map((category) => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-700">Gender</label>
            <select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            >
              <option value="" disabled>Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>
        <button
          type="submit"
          className={`w-full p-4 rounded-lg text-white font-semibold flex items-center justify-center transition-colors duration-300 ${
            isUploading || needsCropping || !frontalPhoto || !ageCategory || !gender
              ? "bg-blue-300 cursor-not-allowed"
              : "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          }`}
          disabled={isUploading || needsCropping || !frontalPhoto || !ageCategory || !gender}
        >
          {isUploading ? (
            <>
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Uploading...
            </>
          ) : needsCropping ? (
            "Please crop the image"
          ) : (
            <>
              <CloudArrowUpIcon className="w-5 h-5 mr-2" />
              Submit
            </>
          )}
        </button>
      </form>
      {selectedPhoto && (
        <CropModal
          selectedPhoto={selectedPhoto}
          crop={crop}
          zoom={zoom}
          onCropComplete={onCropComplete}
          setCrop={setCrop}
          setZoom={setZoom}
          handleCrop={handleCrop}
          handleUseOriginal={handleUseOriginal}
          setSelectedPhoto={setSelectedPhoto}
          needsCropping={needsCropping}
          selectedAspectRatio={selectedAspectRatio}
          setSelectedAspectRatio={setSelectedAspectRatio}
        />
      )}
    </div>
  );
};
const CropModal = ({
  selectedPhoto,
  crop,
  zoom,
  onCropComplete,
  setCrop,
  setZoom,
  handleCrop,
  handleUseOriginal,
  setSelectedPhoto,
  selectedAspectRatio,
  setSelectedAspectRatio,
}) => (
  <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4">
    <div className="bg-white w-full max-w-lg rounded-xl shadow-2xl flex flex-col" style={{ height: '100%' }}>
      <div className="bg-gray-100 p-4 rounded-t-xl">
        <h2 className="text-xl font-semibold text-gray-800">Crop Image</h2>
        <select
          value={selectedAspectRatio.name}
          onChange={(e) =>
            setSelectedAspectRatio(
              ALLOWED_ASPECT_RATIOS.find((ratio) => ratio.name === e.target.value)
            )
          }
          className="w-full mt-2 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          {ALLOWED_ASPECT_RATIOS.map((ratio) => (
            <option key={ratio.name} value={ratio.name}>
              {ratio.name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex-grow relative">
        <Cropper
          image={selectedPhoto.url}
          crop={crop}
          zoom={zoom}
          aspect={selectedAspectRatio.value}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className="bg-gray-100 p-4 rounded-b-xl flex justify-between items-center">
        <button
          onClick={() => setSelectedPhoto(null)}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors duration-300"
        >
          Cancel
        </button>
        <button
        onClick={handleUseOriginal}
        className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-colors duration-300"
      >
        Use as is
      </button>
        <button
          onClick={handleCrop}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
        >
          Crop
        </button>
      </div>
    </div>
  </div>
);

const FileUpload = ({ label, onChange, preview }) => (
  <div>
  <label className="block mb-2 text-sm font-medium text-gray-700">{label}</label>
  <div className="relative border-2 border-dashed border-gray-300 rounded-lg p-6 hover:border-blue-500 transition-colors duration-300">
    <input
      type="file"
      accept="image/*"
      onChange={onChange}
      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
    />
    {preview ? (
      <img
        src={preview}
        alt={`${label} Preview`}
        className="max-h-48 mx-auto rounded-lg shadow-md"
      />
    ) : (
      <div className="text-center">
        <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />
        <p className="mt-2 text-sm text-gray-500">
          Click or drag file to upload
        </p>
      </div>
    )}
  </div>
</div>
);

export default UploadPhotosComponent;


// import React, { useState } from "react";
// import Cropper from "react-easy-crop";
// import { getCroppedImg } from "../pages/cropImage";
// import usePhotoApi from "../services/photoService";
// import { PhotoIcon, CloudArrowUpIcon } from "@heroicons/react/24/outline";


// const ALLOWED_ASPECT_RATIOS = [
//   { name: "1:1", value: 1 },
//   { name: "3:4", value: 3 / 4 },
//   { name: "2:3", value: 2 / 3 },
//   { name: "9:16", value: 9 / 16 },
//   { name: "1:2", value: 1 / 2 },
// ];

// const AGE_CATEGORIES = [
//   "toddler",
//   "young child",
//   "child",
//   "teenager",
//   "adult",
//   "senior"
// ];

// const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

// const checkAspectRatio = (width, height) => {
//   const aspectRatio = width / height;
//   return ALLOWED_ASPECT_RATIOS.some(
//     (ratio) => Math.abs(aspectRatio - ratio.value) < 0.01
//   );
// };

// const UploadPhotosComponent = ({ onUploadSuccess }) => {
//   const { uploadPhotos } = usePhotoApi();
//   const [frontalPhoto, setFrontalPhoto] = useState(null);
//   const [frontalPhotoPreview, setFrontalPhotoPreview] = useState(null);
//   const [isUploading, setIsUploading] = useState(false);
//   const [selectedPhoto, setSelectedPhoto] = useState(null);
//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1);
//   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
//   const [needsCropping, setNeedsCropping] = useState(false);
//   const [selectedAspectRatio, setSelectedAspectRatio] = useState(
//     ALLOWED_ASPECT_RATIOS[0]
//   );
//   const [ageCategory, setAgeCategory] = useState("");
//   const [gender, setGender] = useState("");

//   const handlePhotoChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       if (file.size > MAX_FILE_SIZE) {
//         alert("File size exceeds 5 MB limit. Please choose a smaller file.");
//         return;
//       }
//       const img = new Image();
//       img.src = URL.createObjectURL(file);
//       img.onload = () => {
//         const needsCropping = !checkAspectRatio(img.width, img.height);
//         setNeedsCropping(needsCropping);

//         const localUrl = URL.createObjectURL(file);
//         setFrontalPhoto(file);
//         setFrontalPhotoPreview(localUrl);
//         setSelectedPhoto({ url: localUrl, file: file });
//         setCrop({ x: 0, y: 0 });
//         setZoom(1);
//       };
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (frontalPhoto && ageCategory && gender) {
//       setIsUploading(true);
//       try {
//         const response = await uploadPhotos(frontalPhoto, ageCategory, gender);
//         setFrontalPhoto(null);
//         setFrontalPhotoPreview(null);
//         setAgeCategory("");
//         setGender("");
//         onUploadSuccess(response);
//       } catch (error) {
//         console.error('Failed to upload photo:', error);
//       } finally {
//         setIsUploading(false);
//       }
//     } else {
//       alert('Please select a photo and fill out all fields before submitting.');
//     }
//   };

//   const handleCrop = async () => {
//     setSelectedPhoto(null);
//     try {
//       const croppedImage = await getCroppedImg(
//         selectedPhoto.url,
//         croppedAreaPixels
//       );
//       const blob = await fetch(croppedImage).then((res) => res.blob());
//       const localUrl = URL.createObjectURL(blob);
//       setFrontalPhoto(blob);
//       setFrontalPhotoPreview(localUrl);
//       setNeedsCropping(false);
//     } catch (error) {
//       console.error("Error cropping image:", error);
//     }
//   };

//   const handleUseOriginal = async () => {
//     if (needsCropping) {
//       alert("The image aspect ratio is not optimal. Please crop the image.");
//       return;
//     }
//     setSelectedPhoto(null);
//     if (selectedPhoto && selectedPhoto.file) {
//       try {
//         const localUrl = URL.createObjectURL(selectedPhoto.file);
//         setFrontalPhoto(selectedPhoto.file);
//         setFrontalPhotoPreview(localUrl);
//       } catch (error) {
//         console.error("Error using original photo:", error);
//       }
//     }
//   };

//   const onCropComplete = (croppedArea, croppedAreaPixels) => {
//     setCroppedAreaPixels(croppedAreaPixels);
//   };

//   return (
//     <div className="space-y-6">
//       <form onSubmit={handleSubmit} className="space-y-6">
//         <FileUpload
//           label="Frontal Photo"
//           onChange={handlePhotoChange}
//           preview={frontalPhotoPreview}
//         />
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//         <div>
//             <label className="block mb-2 font-semibold text-gray-700">Age Category</label>
//             <select
//               value={ageCategory}
//               onChange={(e) => setAgeCategory(e.target.value)}
//               className="w-full p-2 border border-gray-300 rounded-md"
//               required
//             >
//               <option value="" disabled>Select age category</option>
//               {AGE_CATEGORIES.map((category) => (
//                 <option key={category} value={category}>{category}</option>
//               ))}
//             </select>
//           </div>
//           <div>
//             <label className="block mb-2 font-semibold text-gray-700">Gender</label>
//             <select
//               value={gender}
//               onChange={(e) => setGender(e.target.value)}
//               className="w-full p-2 border border-gray-300 rounded-md"
//               required
//             >
//               <option value="" disabled>Select gender</option>
//               <option value="male">Male</option>
//               <option value="female">Female</option>
//               <option value="other">Other</option>
//             </select>
//           </div>
//         </div>
//         <button
//           type="submit"
//           className={`w-full p-3 rounded-lg text-white font-semibold flex items-center justify-center ${
//             isUploading || needsCropping
//               ? "bg-blue-400 cursor-not-allowed"
//               : "bg-blue-500 hover:bg-blue-600"
//           } transition-colors duration-300`}
//           // disabled={isUploading || needsCropping}
//           disabled={isUploading || needsCropping || !frontalPhoto || !ageCategory || !gender}
//         >
//           {isUploading ? (
//             <>
//               <svg
//                 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//               >
//                 <circle
//                   className="opacity-25"
//                   cx="12"
//                   cy="12"
//                   r="10"
//                   stroke="currentColor"
//                   strokeWidth="4"
//                 ></circle>
//                 <path
//                   className="opacity-75"
//                   fill="currentColor"
//                   d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
//                 ></path>
//               </svg>
//               Uploading...
//             </>
//           ) : needsCropping ? (
//             "Please crop the image"
//           ) : (
//             <>
//               <CloudArrowUpIcon className="w-5 h-5 mr-2" />
//               Submit
//             </>
//           )}
//         </button>
//       </form>
//       {selectedPhoto && (
//         <CropModal
//           selectedPhoto={selectedPhoto}
//           crop={crop}
//           zoom={zoom}
//           onCropComplete={onCropComplete}
//           setCrop={setCrop}
//           setZoom={setZoom}
//           handleCrop={handleCrop}
//           handleUseOriginal={handleUseOriginal}
//           setSelectedPhoto={setSelectedPhoto}
//           needsCropping={needsCropping}
//           selectedAspectRatio={selectedAspectRatio}
//           setSelectedAspectRatio={setSelectedAspectRatio}
//         />
//       )}
//     </div>
//   );
// };
// const CropModal = ({
//   selectedPhoto,
//   crop,
//   zoom,
//   onCropComplete,
//   setCrop,
//   setZoom,
//   handleCrop,
//   setSelectedPhoto,
//   selectedAspectRatio,
//   setSelectedAspectRatio,
// }) => (
//   <div className="fixed inset-0 bg-gray-900 z-50 flex items-center justify-center">
//     <div className="bg-gray-800 w-full max-w-md flex flex-col" style={{ height: '80vh' }}>
//       <div className="bg-gray-800 text-white p-4">
//         <h2 className="text-xl font-semibold">Crop Image</h2>
//         <select
//           value={selectedAspectRatio.name}
//           onChange={(e) =>
//             setSelectedAspectRatio(
//               ALLOWED_ASPECT_RATIOS.find((ratio) => ratio.name === e.target.value)
//             )
//           }
//           className="w-full bg-gray-700 text-white px-3 py-2 rounded-md mt-2"
//         >
//           {ALLOWED_ASPECT_RATIOS.map((ratio) => (
//             <option key={ratio.name} value={ratio.name}>
//               {ratio.name}
//             </option>
//           ))}
//         </select>
//       </div>
//       <div className="flex-grow relative">
//         <Cropper
//           image={selectedPhoto.url}
//           crop={crop}
//           zoom={zoom}
//           aspect={selectedAspectRatio.value}
//           onCropChange={setCrop}
//           onCropComplete={onCropComplete}
//           onZoomChange={setZoom}
//         />
//       </div>
//       <div className="bg-gray-800 p-4 flex justify-between items-center">
//         <button
//           onClick={() => setSelectedPhoto(null)}
//           className="px-4 py-2 bg-red-500 text-white rounded-md"
//         >
//           Cancel
//         </button>
//         <button
//           onClick={handleCrop}
//           className="px-4 py-2 bg-green-500 text-white rounded-md"
//         >
//           Crop
//         </button>
//       </div>
//     </div>
//   </div>
// );

// const FileUpload = ({ label, onChange, preview }) => (
//   <div>
//     <label className="block mb-2 font-semibold text-gray-700">{label}</label>
//     <div className="relative border-2 border-dashed border-gray-300 rounded-lg p-4 hover:border-blue-500 transition-colors duration-300">
//       <input
//         type="file"
//         accept="image/*"
//         onChange={onChange}
//         className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
//       />
//       {preview ? (
//         <img
//           src={preview}
//           alt={`${label} Preview`}
//           className="max-h-48 mx-auto rounded-lg"
//         />
//       ) : (
//         <div className="text-center">
//           <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />
//           <p className="mt-1 text-sm text-gray-600">
//             Click or drag file to upload
//           </p>
//         </div>
//       )}
//     </div>
//   </div>
// );

// export default UploadPhotosComponent;


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (frontalPhoto) {
  //     setIsUploading(true);
  //     try {
  //       const response = await uploadPhotos([frontalPhoto]);
  //       setFrontalPhoto(null);
  //       setFrontalPhotoPreview(null);
  //       onUploadSuccess(response);
  //     } catch (error) {
  //       console.error("Failed to upload photo:", error);
  //     } finally {
  //       setIsUploading(false);
  //     }
  //   } else {
  //     alert("Please select a photo before submitting.");
  //   }
  // };


        // if (needsCropping) {
        //   alert("The image aspect ratio is not optimal. Please crop the image to continue.");
        // }

        // const CropModal = ({
//   selectedPhoto,
//   crop,
//   zoom,
//   onCropComplete,
//   setCrop,
//   setZoom,
//   handleCrop,
//   setSelectedPhoto,
//   selectedAspectRatio,
//   setSelectedAspectRatio,
// }) => (
//   <div className="fixed inset-0 bg-black z-50 flex flex-col">
//     <div className="bg-gray-800 text-white p-4 flex justify-between items-center">
//       <h2 className="text-xl font-semibold">Crop Image</h2>
//       <select
//         value={selectedAspectRatio.name}
//         onChange={(e) =>
//           setSelectedAspectRatio(
//             ALLOWED_ASPECT_RATIOS.find((ratio) => ratio.name === e.target.value)
//           )
//         }
//         className="bg-gray-700 text-white px-3 py-2 rounded-md"
//       >
//         {ALLOWED_ASPECT_RATIOS.map((ratio) => (
//           <option key={ratio.name} value={ratio.name}>
//             {ratio.name}
//           </option>
//         ))}
//       </select>
//     </div>
//     <div className="flex-grow relative">
//       <Cropper
//         image={selectedPhoto.url}
//         crop={crop}
//         zoom={zoom}
//         aspect={selectedAspectRatio.value}
//         onCropChange={setCrop}
//         onCropComplete={onCropComplete}
//         onZoomChange={setZoom}
//       />
//     </div>
//     <div className="bg-gray-800 p-4 flex justify-end space-x-4">
//       <button
//         onClick={() => setSelectedPhoto(null)}
//         className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300"
//       >
//         Cancel
//       </button>
//       <button
//         onClick={handleCrop}
//         className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300"
//       >
//         Crop
//       </button>
//     </div>
//   </div>
// );
