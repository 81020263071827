import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { XMarkIcon } from '@heroicons/react/24/outline';
import config from '../utils/config';
const base_backend = `${config.apiUrl}/api`;
const AuthBanner = () => {
  const { user } = useAuth();
  const [isVisible, setIsVisible] = useState(true);

  const handleResendEmail = async () => {
    try {
      const response = await fetch(`${base_backend}/auth/resend_verification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email }),
      });
      
      if (response.ok) {
        alert('Verification email resent successfully!');
      } else {
        const errorData = await response.json();
        alert(`Failed to resend email: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error resending verification email:', error);
      alert('An error occurred while resending the verification email.');
    }
  };

  if (!user || user.authenticated || !isVisible) {
    return null;
  }

  return (
    <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
          </svg>
          <span className="text-sm font-medium">
            Authenticate now to get 5 free photos!
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={handleResendEmail}
            className="text-sm underline hover:text-blue-200 transition-colors"
          >
            Resend Verification Email
          </button>
          <button
            onClick={() => setIsVisible(false)}
            className="text-white hover:text-blue-200 transition-colors"
          >
            <XMarkIcon size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AuthBanner;