import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { XMarkIcon, PhotoIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline';
import UploadPhotosComponent from './UploadPhotosComponent';
import ManagePhotosComponent from './ManagePhotosComponent';

const PhotoSelectionModal = ({ isOpen, onClose, onPhotoSelected }) => {
  const [activeTab, setActiveTab] = useState('select');

  const handleUploadSuccess = (response) => {
    onPhotoSelected(response.requestId);
    onClose();
  };

  const handlePhotoSelect = (requestId) => {
    console.log('Selected photo from photoselectionmodal:', requestId);
    onPhotoSelected(requestId);
    onClose();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-semibold leading-6 text-gray-900 mb-4"
                >
                  Select or Upload Photos
                </Dialog.Title>
                <button
                  onClick={onClose}
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
                <div className="mb-6">
                  <nav className="flex space-x-4" aria-label="Tabs">
                    <button
                      className={`${
                        activeTab === 'select'
                          ? 'bg-blue-100 text-blue-700'
                          : 'text-gray-500 hover:text-gray-700'
                      } px-3 py-2 font-medium text-sm rounded-md`}
                      onClick={() => setActiveTab('select')}
                    >
                      <PhotoIcon className="h-5 w-5 inline-block mr-2" />
                      Select Existing
                    </button>
                    <button
                      className={`${
                        activeTab === 'upload'
                          ? 'bg-blue-100 text-blue-700'
                          : 'text-gray-500 hover:text-gray-700'
                      } px-3 py-2 font-medium text-sm rounded-md`}
                      onClick={() => setActiveTab('upload')}
                    >
                      <CloudArrowUpIcon className="h-5 w-5 inline-block mr-2" />
                      Upload New
                    </button>
                  </nav>
                </div>
                <div className="mt-4">
                  {activeTab === 'select' ? (
                    <ManagePhotosComponent onSelect={handlePhotoSelect} showDeleteButton={false} />
                  ) : (
                    <UploadPhotosComponent onUploadSuccess={handleUploadSuccess} />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PhotoSelectionModal;
