import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const LookBook = ({ avatarExamples, handleTryPrompt, selectedStyle }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentExamples = avatarExamples[selectedStyle] || [];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex > 0 ? prevIndex - 1 : currentExamples.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex < currentExamples.length - 1 ? prevIndex + 1 : 0
    );
  };

  if (currentExamples.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-4 max-w-sm mx-auto">
        <h2 className="text-xl font-bold mb-3">Examples</h2>
        <p className="text-center text-gray-600">No examples available for the selected style.</p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 max-w-sm mx-auto">
      <h2 className="text-xl font-bold mb-3">Examples</h2>
      <div className="relative mb-3">
        <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg">
          <img 
            src={currentExamples[currentIndex].photo_url} 
            alt={`Avatar example ${currentIndex + 1}`}
            className="object-cover w-full h-full"
          />
        </div>
        <button
          onClick={handlePrev}
          className="absolute left-1 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1 shadow-md"
        >
          <ChevronLeftIcon className="h-4 w-4" />
        </button>
        <button
          onClick={handleNext}
          className="absolute right-1 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1 shadow-md"
        >
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>
      <p className="text-center text-sm text-gray-600 mb-1">
        Prompt: {currentExamples[currentIndex].prompt}
      </p>
      <p className="text-center text-xs text-gray-500 mb-3">
        {currentIndex + 1} / {currentExamples.length}
      </p>
      <button
        onClick={() => handleTryPrompt(currentExamples[currentIndex].prompt)}
        className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
      >
        Try This Prompt
      </button>
    </div>
  );
};

export default LookBook;
