import React, { useState, useCallback, useEffect, useRef } from "react";
import { Oval } from "react-loader-spinner";
import config from "../utils/config";
import useImageGeneration from "../utils/imageGenerationService";
import { useNavigate } from "react-router-dom";
import { avatarExamples } from "../assets/constants";
import Payment from "../components/Payment";
import LookBook from "../components/LookBook";
import CollapsibleSidebar from "../components/CollapsibleSidebar";
import PhotoSelectionModal from "../components/PhotoSelectionModal";
import AvatarDisplay from "../components/AvatarDisplay";
import {
  CameraIcon,
  PencilIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "../context/AuthContext";
const base_backend = `${config.apiUrl}/api`;

const AvatarGenerator = () => {
  const { user } = useAuth();
  const { batchGenerateImages } = useImageGeneration();
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [style, setStyle] = useState("Cartoon");
  const [shotType, setShotType] = useState("");
  const [generatedAvatars, setGeneratedAvatars] = useState([]);
  const [error, setError] = useState(null);
  const [isAuthenticated, setAuthenticated] = useState(true);
  const [statusMessage, setStatusMessage] = useState("");
  const [creditBalance, setCreditBalance] = useState(0);
  const [kpsStatusId, setKpsStatusId] = useState(null);
  const [enhancePrompt, setEnhancedPrompt] = useState(true);
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();


  const photoSectionRef = useRef(null);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await fetch(`${base_backend}/user/user-data`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setCreditBalance(data.credit_balance);
        setAuthenticated(data.authenticated);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError(`Error fetching user data: ${error.message}`);
    }
  }, []);

  useEffect(() => {
    setText("");
    fetchUserData();
  }, [fetchUserData]);

  const handleTryPrompt = (prompt) => {
    if (photoSectionRef.current) {
      photoSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setText(prompt);
  };

  const updateStatusMessage = (newMessage) => {
    setStatusMessage(newMessage);
  };

  const setGeneratedAvatarsCallback = useCallback(
    (avatar) => {
      console.log("THE AVATARS CALL BACK: ", avatar);
      setGeneratedAvatars(avatar);
      fetchUserData();
    },
    [fetchUserData]
  );

  const handleSubmit = async () => {
    console.log(isAuthenticated && (creditBalance===0))
    if (creditBalance >= 100) {
      setIsLoading(true);
      updateStatusMessage("Image Process Initiated...");
      setError(null);
      const avatarData = [
        {
          text,
          shotType,
          style,
          statusId: kpsStatusId,
          enhancePrompt,
        },
      ];

      try {
        await batchGenerateImages(
          avatarData,
          setGeneratedAvatarsCallback,
          setIsLoading,
          setError,
          updateStatusMessage
        );
      } catch (error) {
        console.error("Error generating avatars:", error);
        setError(
          `An error occurred while generating avatars. Please try again: ${error.message}`
        );
      }
    } else {
      if (isAuthenticated && (creditBalance===0)) {
        // Open sidebar
        setError("Insufficient credits.");
        setSidebarOpen(true);
      }
    }
  };


  const handlePhotoSelected = (requestId) => {
    setKpsStatusId(requestId);
  };

  const handleShopRedirect = (avatar) => {
    console.log(avatar)
    navigate('/shop', { state: { selectedAvatar: avatar } });
  };

  return (
    <div className="bg-gray-50 min-h-screen py-12">
      <main className="max-w-6xl mx-auto px-4">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
            {error}
          </div>
        )}

        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Avatar Generator</h1>
          <p className="text-lg">
            Images Remaining:{" "}
            <span className="font-bold">
              {(creditBalance / 100).toFixed(0)}
            </span>
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div
            className="bg-white p-6 rounded-lg shadow-md"
            ref={photoSectionRef}
          >
            <div className="space-y-6">
              <div>
                <h2 className="text-xl font-semibold mb-2 flex items-center">
                  <CameraIcon className="w-6 h-6 mr-2 text-blue-500" />
                  Select Photo
                </h2>
                <button
                  onClick={() => setIsPhotoModalOpen(true)}
                  className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                >
                  Select or Upload Photo
                </button>
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-2 flex items-center">
                  <PencilIcon className="w-6 h-6 mr-2 text-blue-500" />
                  Customize Your Avatar
                </h2>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Style
                    </label>
                    <select
                      value={style}
                      onChange={(e) => setStyle(e.target.value)}
                      className="w-full p-2 rounded border border-gray-300"
                    >
                      <option value="">Select a style</option>
                      <option value="Anime">Anime</option>
                      <option value="Cartoon">Cartoon</option>
                      <option value="Animation">3D Animation</option>
                      <option value="Photorealistic">Photorealistic</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Shot Type
                    </label>
                    <select
                      value={shotType}
                      onChange={(e) => setShotType(e.target.value)}
                      className="w-full p-2 rounded border border-gray-300"
                    >
                      <option value="">Select shot type</option>
                      <option value="Headshot">Headshot</option>
                      <option value="Half-body">Half-body</option>
                      <option value="Full-body">Full-body (Experimental)</option>
                    </select>
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Prompt
                </label>
                <input
                  type="text"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Describe your avatar"
                  className="w-full p-2 rounded border border-gray-300"
                />
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={enhancePrompt}
                  onChange={() => setEnhancedPrompt(!enhancePrompt)}
                  className="mr-2"
                />
                <span className="text-sm text-gray-600">Enhance Prompt</span>
              </div>

              {isLoading ? (
                <div className="text-center flex flex-col items-center justify-center">
                  <p className="mb-2">
                    Please wait, image generation may take a few minutes...
                  </p>
                  {statusMessage && (
                    <p className="mb-4 text-blue-600">{statusMessage}</p>
                  )}
                  <Oval
                    visible={true}
                    height="80"
                    width="80"
                    color="#3B82F6"
                    secondaryColor="#93C5FD"
                    ariaLabel="oval-loading"
                  />
                </div>
              ) : (
                <button
                  onClick={handleSubmit}
                  disabled={
                    isLoading || !kpsStatusId || !text || !style || !shotType
                  }
                  className="w-full p-3 bg-blue-500 text-white font-bold rounded hover:bg-blue-600 transition duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed flex items-center justify-center"
                >
                  <SparklesIcon className="w-5 h-5 mr-2" />
                  Generate Avatar
                </button>
              )}
            </div>

            {generatedAvatars.length > 0 && (
              <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">
                  Generated Avatars
                </h2>
                <AvatarDisplay
                  key={generatedAvatars.map((avatar) => avatar.id).join(",")}
                  avatarGroup={generatedAvatars}
                  setError={setError}
                  onShop={(avatar) => handleShopRedirect(avatar)}
                />
              </div>
            )}
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <LookBook
              avatarExamples={avatarExamples}
              handleTryPrompt={handleTryPrompt}
              selectedStyle={style}
            />
          </div>
        </div>

        <CollapsibleSidebar
          title="Payment"
          position="left"
          actions={[]}
          isOpen={sidebarOpen}
          setIsOpen={setSidebarOpen}
        >
          <Payment setError={setError} />
          <div className="mt-8 bg-gray-100 p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4">Getting Started</h2>
            <ul className="list-disc list-inside space-y-2 text-gray-700">
              <li>Verify email for first image free</li>
              <li>Upload a clear, well-lit image of one person</li>
              <li>Choose style, shot type, and enter a descriptive prompt</li>
              <li>Click generate and wait for your custom avatar</li>
              <li>Not satisfied? Contact us for a credit refund</li>
            </ul>
          </div>
        </CollapsibleSidebar>
      </main>

      <PhotoSelectionModal
        isOpen={isPhotoModalOpen}
        onClose={() => setIsPhotoModalOpen(false)}
        onPhotoSelected={handlePhotoSelected}
      />
    </div>
  );
};

export default AvatarGenerator;
