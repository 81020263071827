import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

const statusMapping = {
  'pending': 'Order Placed',
  'paid': 'Order Placed',
  'Verified': 'In Production',
  'Processing': 'In Production',
  'Shipped': 'Shipped',
  "in progress": "In Progress",
};

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const OrderStatusModal = ({ order, isOpen, onClose }) => {
  if (!order) return null;
  console.log('Order:', order);

  const displayStatus = statusMapping[order.customcat_status] || statusMapping[order.local_status] || 'In Progress';

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-2">
                  Order Details
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    <strong>Status:</strong> {displayStatus}
                  </p>
                  <p className="text-sm text-gray-500">
                    <strong>Order Date:</strong> {formatDate(order.order_date)}
                  </p>
                  <p className="text-sm text-gray-500">
                    <strong>Total:</strong> ${parseFloat(order.order_total).toFixed(2)}
                  </p>
                  
                  <h4 className="mt-4 font-medium">Shipping Information</h4>
                  <p className="text-sm text-gray-500">{order.customer_name}</p>
                  <p className="text-sm text-gray-500">{order.customer_address}</p>

                  <h4 className="mt-4 font-medium">Shipments</h4>
                  {order.shipments?.length > 0 ? (
                    order.shipments?.map((shipment, index) => (
                      <div key={index} className="mt-2">
                        <p className="text-sm text-gray-500"><strong>Method:</strong> {shipment.METHOD}</p>
                        <p className="text-sm text-gray-500"><strong>Tracking ID:</strong> {shipment.TRACKING_ID}</p>
                        <p className="text-sm text-gray-500"><strong>Vendor:</strong> {shipment.VENDOR}</p>
                        <p className="text-sm text-gray-500"><strong>Number of Items:</strong> {shipment.NUMBER_ITEMS}</p>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm text-gray-500">N/A</p>
                  )}

                  <h4 className="mt-4 font-medium">Line Items</h4>
                  {order.line_items?.map((item, index) => (
                    <div key={index} className="mt-2">
                      <p className="text-sm text-gray-500"><strong>Product:</strong> {item.PRODUCT_NAME}</p>
                      <p className="text-sm text-gray-500"><strong>Status:</strong> {statusMapping[item.STATUS] || item.STATUS}</p>
                    </div>
                  ))}
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default OrderStatusModal;
