import { useAuth } from "../context/AuthContext";
import config from "../utils/config";

const base_backend = `${config.apiUrl}/api`;

const useImageGeneration = () => {
  const { user } = useAuth();

  const initiateBatchGeneration = async (imagePrompts) => {
    try {
      const data = {
        userId: user.id,
        avatarData: imagePrompts,
      };

      const response = await fetch(`${base_backend}/image/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.accessToken}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Failed to initiate batch generation");
      }

      const responseData = await response.json();
      const statusIds = responseData.status_ids;
      const imagePromptsData = responseData.image_prompts;
      return statusIds.map((statusId, index) => ({
        id: statusId,
        status: "pending",
        imagePrompts: imagePromptsData.slice(index * 3, (index + 1) * 3),
      }));
    } catch (error) {
      console.error("Error initiating image generation:", error);
      throw error;
    }
  };

  const pollForBatchImageResult = async (statusId, setStatusMessage) => {
    let result = null;
    const maxAttempts = 30;
    let attempts = 0;

    try {
      while (attempts < maxAttempts && !result) {
        attempts++;
        await new Promise((resolve) => setTimeout(resolve, 5000));
        const response = await fetch(
          `${base_backend}/image/poll/${user.id}/${statusId}`,
          {
            headers: { Authorization: `Bearer ${user.accessToken}` },
          }
        );
        if (response.ok) {
          const data = await response.json();
          const status = data.status;
          setStatusMessage(status);
          if (data.status === "COMPLETED") {
            result = data.images;
            break;
          }
        } else {
          throw new Error(`Error occurred while polling for image status: ${response.status}`);
        }
      }
      if (!result) {
        throw new Error("Polling max attempts reached without completion");
      }
    } catch (error) {
      console.error("Error polling for image result:", error);
      throw error;
    }

    return result;
  };

  const batchGenerateImages = async (
    avatarData,
    setGeneratedAvatarsCallback,
    setIsGeneratingImages,
    setError,
    setStatusMessage
  ) => {
    try {
      setIsGeneratingImages(true);
      setStatusMessage("Processing...");
      const initiationResults = await initiateBatchGeneration(avatarData);

      initiationResults.forEach((initiation) => {
        pollForBatchImageResult(initiation.id, setStatusMessage)
          .then((results) => {
            setGeneratedAvatarsCallback(results);
            setIsGeneratingImages(false);
          })
          .catch((error) => {
            const errorMessage = `Failed to generate images: ${error}`;
            console.error(errorMessage);
            setError(errorMessage);
          });
      });
    } catch (error) {
      const errorMessage = `An error occurred while generating the images. Please try again: ${error}`;
      console.error(errorMessage);
      setError(errorMessage);
      setIsGeneratingImages(false);
    }
  };

  return { batchGenerateImages };
};

export default useImageGeneration;
