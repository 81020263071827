import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowDownTrayIcon,
  EyeIcon,
  TrashIcon,
  XMarkIcon,
  ShoppingBagIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { motion, AnimatePresence } from "framer-motion";
import config from "../utils/config";
const base_backend = `${config.apiUrl}/api`;

const IconButton = ({ onClick, icon: Icon, label, color }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    onClick={onClick}
    className={`group flex items-center p-2 rounded-full hover:rounded-lg transition-all duration-150 focus:outline-none focus:ring-2 focus:ring-opacity-50 ${color}`}
  >
    <Icon className="w-6 h-6 mr-0 group-hover:mr-2 transition-all duration-150" />
    <span className="w-0 overflow-hidden group-hover:w-auto transition-all duration-150 whitespace-nowrap">
      {label}
    </span>
  </motion.button>
);

const AvatarDisplay = ({ avatarGroup, setError, handleDelete, onShop }) => {
  const { user } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [avatars, setAvatars] = useState(avatarGroup);
  const [isPromptExpanded, setIsPromptExpanded] = useState(false);
  const [isPromptOverflowing, setIsPromptOverflowing] = useState(false);
  const promptRef = useRef(null);
  const currentAvatar = avatars[currentIndex];

  useEffect(() => {
    if (promptRef.current) {
      setIsPromptOverflowing(
        promptRef.current.scrollHeight > promptRef.current.clientHeight
      );
    }
  }, [currentAvatar, promptRef]);

  const handlePrev = () =>
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : avatars.length - 1));
  const handleNext = () =>
    setCurrentIndex((prev) => (prev < avatars.length - 1 ? prev + 1 : 0));
  
  const handleDownload = async (imageId) => {
    const proxyUrl = `${base_backend}/images/${imageId}`;
    try {
      const response = await fetch(proxyUrl, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
  
      if (!response.ok) throw new Error("Failed to fetch image");
  
      const blob = await response.blob();
      const urlObject = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlObject;
      link.download = "generated_avatar.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error("Error downloading image:", error);
      setError("Failed to download image. Please try again.");
    }
  };

  const handleViewImage = () => setIsViewModalOpen(true);

  const handleLocalDelete = async (statusId) => {
    await handleDelete(statusId);
    setAvatars(avatars.filter((avatar) => avatar.status_id !== statusId));
    if (avatars.length === 1) {
      setCurrentIndex(0);
    } else if (currentIndex === avatars.length - 1) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const togglePrompt = () => setIsPromptExpanded(!isPromptExpanded);

  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-md">
      {avatars.length > 0 ? (
        <>
          <div className="relative w-full" style={{ paddingBottom: "100%" }}>
            <img
              src={currentAvatar.photo_url}
              alt={`Generated Avatar ${currentIndex + 1}`}
              className="absolute top-0 left-0 w-full h-full object-contain"
            />
            <div className="absolute top-1/2 left-0 transform -translate-y-1/2">
              <IconButton
                onClick={handlePrev}
                icon={ChevronLeftIcon}
                label="Previous"
                color="bg-gray-800 bg-opacity-50 text-white hover:bg-opacity-75"
              />
            </div>
            <div className="absolute top-1/2 right-0 transform -translate-y-1/2">
              <IconButton
                onClick={handleNext}
                icon={ChevronRightIcon}
                label="Next"
                color="bg-gray-800 bg-opacity-50 text-white hover:bg-opacity-75"
              />
            </div>
          </div>
          <div className="p-4">
            <p className="text-sm text-gray-600 mb-2">
              Generated on:{" "}
              {new Date(currentAvatar.created_at).toLocaleDateString()}
            </p>
            <div className="mb-4">
              <p className="text-sm font-medium text-gray-700 mb-1">Prompt:</p>
              <div
                ref={promptRef}
                className={`text-sm text-gray-600 ${
                  isPromptExpanded ? "" : "line-clamp-2"
                }`}
              >
                {currentAvatar.prompt}
              </div>
              {isPromptOverflowing && (
                <button
                  onClick={togglePrompt}
                  className="flex items-center text-sm text-blue-500 mt-1 focus:outline-none"
                >
                  {isPromptExpanded ? (
                    <>
                      <ChevronUpIcon className="w-4 h-4 mr-1" />
                      Show less
                    </>
                  ) : (
                    <>
                      <ChevronDownIcon className="w-4 h-4 mr-1" />
                      Show more
                    </>
                  )}
                </button>
              )}
            </div>
            <div className="flex justify-between">
              <IconButton
                onClick={() => handleDownload(currentAvatar.id)}
                icon={ArrowDownTrayIcon}
                label="Download"
                color="bg-blue-500 text-white hover:bg-blue-600"
              />
              <IconButton
                onClick={handleViewImage}
                icon={EyeIcon}
                label="View"
                color="bg-blue-500 text-white hover:bg-blue-600"
              />
              <IconButton
                onClick={() => onShop(currentAvatar)}
                icon={ShoppingBagIcon}
                label="Shop"
                color="bg-blue-500 text-white hover:bg-blue-600"
              />
              <IconButton
                onClick={() => handleLocalDelete(currentAvatar.status_id)}
                icon={TrashIcon}
                label="Delete"
                color="bg-red-500 text-white hover:bg-red-600"
              />
            </div>
          </div>
        </>
      ) : (
        <div className="p-4 text-center text-gray-600">
          No avatars available.
        </div>
      )}
      {isViewModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
          onClick={() => setIsViewModalOpen(false)}
        >
          <div
            className="bg-white rounded-lg overflow-hidden flex items-center justify-center relative"
            style={{
              maxWidth: "calc(100vw - 2rem)",
              maxHeight: "calc(100vh - 2rem)",
              width: "auto",
              height: "auto",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="p-4 relative">
              <img
                src={currentAvatar.photo_url}
                alt="Full size avatar"
                className="max-w-full max-h-full object-contain rounded"
                style={{
                  maxWidth: "calc(100vw - 4rem)",
                  maxHeight: "calc(100vh - 4rem)",
                }}
              />
              <button
                onClick={() => setIsViewModalOpen(false)}
                className="absolute top-2 right-2 p-1 bg-gray-800 text-white rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                aria-label="Close"
              >
                <XMarkIcon className="w-6 h-6" />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handlePrev();
                }}
                className="absolute top-1/2 left-4 transform -translate-y-1/2 p-2 bg-gray-800 bg-opacity-50 text-white rounded-full hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                aria-label="Previous"
              >
                <ChevronLeftIcon className="w-6 h-6" />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleNext();
                }}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 p-2 bg-gray-800 bg-opacity-50 text-white rounded-full hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                aria-label="Next"
              >
                <ChevronRightIcon className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvatarDisplay;
// import React, { useState } from "react";
// import { useAuth } from "../context/AuthContext";
// import {
//   ChevronLeftIcon,
//   ChevronRightIcon,
//   ArrowDownTrayIcon,
//   EyeIcon,
//   TrashIcon,
//   XMarkIcon,
//   ShoppingBagIcon,
//   ChevronDownIcon,
//   ChevronUpIcon,
// } from "@heroicons/react/24/outline";
// import { motion, AnimatePresence } from "framer-motion";
// import config from "../utils/config";
// const base_backend = `${config.apiUrl}/api`;

// const IconButton = ({ onClick, icon: Icon, label, color }) => (
//   <motion.button
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//     onClick={onClick}
//     className={`group flex items-center p-2 rounded-full hover:rounded-lg transition-all duration-150 focus:outline-none focus:ring-2 focus:ring-opacity-50 ${color}`}
//   >
//     <Icon className="w-6 h-6 mr-0 group-hover:mr-2 transition-all duration-150" />
//     <span className="w-0 overflow-hidden group-hover:w-auto transition-all duration-150 whitespace-nowrap">
//       {label}
//     </span>
//   </motion.button>
// );

// const AvatarDisplay = ({ avatarGroup, setError, handleDelete, onShop }) => {
//   const { user } = useAuth();
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [isViewModalOpen, setIsViewModalOpen] = useState(false);
//   const [avatars, setAvatars] = useState(avatarGroup);
//   const [isPromptExpanded, setIsPromptExpanded] = useState(false);
//   const currentAvatar = avatars[currentIndex];

//   const handlePrev = () =>
//     setCurrentIndex((prev) => (prev > 0 ? prev - 1 : avatars.length - 1));
//   const handleNext = () =>
//     setCurrentIndex((prev) => (prev < avatars.length - 1 ? prev + 1 : 0));
  
//   const handleDownload = async (imageId) => {
//     const proxyUrl = `${base_backend}/images/${imageId}`;
//     try {
//       const response = await fetch(proxyUrl, {
//         headers: {
//           Authorization: `Bearer ${user.accessToken}`,
//         },
//       });
  
//       if (!response.ok) throw new Error("Failed to fetch image");
  
//       const blob = await response.blob();
//       const urlObject = URL.createObjectURL(blob);
//       const link = document.createElement("a");
//       link.href = urlObject;
//       link.download = "generated_avatar.png";
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//       URL.revokeObjectURL(urlObject);
//     } catch (error) {
//       console.error("Error downloading image:", error);
//       setError("Failed to download image. Please try again.");
//     }
//   };

//   const handleViewImage = () => setIsViewModalOpen(true);

//   const handleLocalDelete = async (statusId) => {
//     await handleDelete(statusId);
//     setAvatars(avatars.filter((avatar) => avatar.status_id !== statusId));
//     if (avatars.length === 1) {
//       setCurrentIndex(0);
//     } else if (currentIndex === avatars.length - 1) {
//       setCurrentIndex(currentIndex - 1);
//     }
//   };

//   const togglePrompt = () => setIsPromptExpanded(!isPromptExpanded);

//   return (
//     <div className="bg-white rounded-lg overflow-hidden shadow-md">
//       {avatars.length > 0 ? (
//         <>
//           <div className="relative w-full" style={{ paddingBottom: "100%" }}>
//             <img
//               src={currentAvatar.photo_url}
//               alt={`Generated Avatar ${currentIndex + 1}`}
//               className="absolute top-0 left-0 w-full h-full object-contain"
//             />
//             <div className="absolute top-1/2 left-0 transform -translate-y-1/2">
//               <IconButton
//                 onClick={handlePrev}
//                 icon={ChevronLeftIcon}
//                 label="Previous"
//                 color="bg-gray-800 bg-opacity-50 text-white hover:bg-opacity-75"
//               />
//             </div>
//             <div className="absolute top-1/2 right-0 transform -translate-y-1/2">
//               <IconButton
//                 onClick={handleNext}
//                 icon={ChevronRightIcon}
//                 label="Next"
//                 color="bg-gray-800 bg-opacity-50 text-white hover:bg-opacity-75"
//               />
//             </div>
//           </div>
//           <div className="p-4">
//             <p className="text-sm text-gray-600 mb-2">
//               Generated on:{" "}
//               {new Date(currentAvatar.created_at).toLocaleDateString()}
//             </p>
//             <div className="mb-4">
//               <button
//                 onClick={togglePrompt}
//                 className="flex items-center justify-between w-full text-left text-sm text-gray-600 focus:outline-none"
//               >
//                 <span>Prompt</span>
//                 {isPromptExpanded ? (
//                   <ChevronUpIcon className="w-4 h-4" />
//                 ) : (
//                   <ChevronDownIcon className="w-4 h-4" />
//                 )}
//               </button>
//               <AnimatePresence>
//                 {isPromptExpanded && (
//                   <motion.div
//                     initial={{ height: 0, opacity: 0 }}
//                     animate={{ height: "auto", opacity: 1 }}
//                     exit={{ height: 0, opacity: 0 }}
//                     transition={{ duration: 0.3 }}
//                     className="overflow-hidden"
//                   >
//                     <p className="text-sm text-gray-600 mt-2">
//                       {currentAvatar.prompt}
//                     </p>
//                   </motion.div>
//                 )}
//               </AnimatePresence>
//             </div>
//             <div className="flex justify-between">
//               <IconButton
//                 onClick={() => handleDownload(currentAvatar.id)}
//                 icon={ArrowDownTrayIcon}
//                 label="Download"
//                 color="bg-blue-500 text-white hover:bg-blue-600"
//               />
//               <IconButton
//                 onClick={handleViewImage}
//                 icon={EyeIcon}
//                 label="View"
//                 color="bg-blue-500 text-white hover:bg-blue-600"
//               />
//               <IconButton
//                 onClick={() => onShop(currentAvatar)}
//                 icon={ShoppingBagIcon}
//                 label="Shop"
//                 color="bg-blue-500 text-white hover:bg-blue-600"
//               />
//               <IconButton
//                 onClick={() => handleLocalDelete(currentAvatar.status_id)}
//                 icon={TrashIcon}
//                 label="Delete"
//                 color="bg-red-500 text-white hover:bg-red-600"
//               />
//             </div>
//           </div>
//         </>
//       ) : (
//         <div className="p-4 text-center text-gray-600">
//           No avatars available.
//         </div>
//       )}
//       {isViewModalOpen && (
//         <div
//           className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
//           onClick={() => setIsViewModalOpen(false)}
//         >
//           <div
//             className="bg-white rounded-lg overflow-hidden flex items-center justify-center relative"
//             style={{
//               maxWidth: "calc(100vw - 2rem)",
//               maxHeight: "calc(100vh - 2rem)",
//               width: "auto",
//               height: "auto",
//             }}
//             onClick={(e) => e.stopPropagation()}
//           >
//             <div className="p-4 relative">
//               <img
//                 src={currentAvatar.photo_url}
//                 alt="Full size avatar"
//                 className="max-w-full max-h-full object-contain rounded"
//                 style={{
//                   maxWidth: "calc(100vw - 4rem)",
//                   maxHeight: "calc(100vh - 4rem)",
//                 }}
//               />
//               <button
//                 onClick={() => setIsViewModalOpen(false)}
//                 className="absolute top-2 right-2 p-1 bg-gray-800 text-white rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
//                 aria-label="Close"
//               >
//                 <XMarkIcon className="w-6 h-6" />
//               </button>
//               <button
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   handlePrev();
//                 }}
//                 className="absolute top-1/2 left-4 transform -translate-y-1/2 p-2 bg-gray-800 bg-opacity-50 text-white rounded-full hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
//                 aria-label="Previous"
//               >
//                 <ChevronLeftIcon className="w-6 h-6" />
//               </button>
//               <button
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   handleNext();
//                 }}
//                 className="absolute top-1/2 right-4 transform -translate-y-1/2 p-2 bg-gray-800 bg-opacity-50 text-white rounded-full hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
//                 aria-label="Next"
//               >
//                 <ChevronRightIcon className="w-6 h-6" />
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AvatarDisplay;
// import React, { useState } from "react";
// import { useAuth } from "../context/AuthContext";
// import {
//   ChevronLeftIcon,
//   ChevronRightIcon,
//   ArrowDownTrayIcon,
//   EyeIcon,
//   TrashIcon,
//   XMarkIcon,
//   ShoppingBagIcon,
// } from "@heroicons/react/24/outline";
// import { motion } from "framer-motion";
// import config from "../utils/config";
// const base_backend = `${config.apiUrl}/api`;

// const IconButton = ({ onClick, icon: Icon, label, color }) => (
//   <motion.button
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//     onClick={onClick}
//     className={`group flex items-center p-2 rounded-full hover:rounded-lg transition-all duration-150 focus:outline-none focus:ring-2 focus:ring-opacity-50 ${color}`}
//   >
//     <Icon className="w-6 h-6 mr-0 group-hover:mr-2 transition-all duration-150" />
//     <span className="w-0 overflow-hidden group-hover:w-auto transition-all duration-150 whitespace-nowrap">
//       {label}
//     </span>
//   </motion.button>
// );

// const AvatarDisplay = ({ avatarGroup, setError, handleDelete, onShop }) => {
//   const { user } = useAuth();
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [isViewModalOpen, setIsViewModalOpen] = useState(false);
//   const [avatars, setAvatars] = useState(avatarGroup);
//   const currentAvatar = avatars[currentIndex];

//   const handlePrev = () =>
//     setCurrentIndex((prev) => (prev > 0 ? prev - 1 : avatars.length - 1));
//   const handleNext = () =>
//     setCurrentIndex((prev) => (prev < avatars.length - 1 ? prev + 1 : 0));
  
//   const handleDownload = async (imageId) => {
//     const proxyUrl = `${base_backend}/images/${imageId}`;
//     try {
//       const response = await fetch(proxyUrl, {
//         headers: {
//           Authorization: `Bearer ${user.accessToken}`,
//         },
//       });
  
//       if (!response.ok) throw new Error("Failed to fetch image");
  
//       const blob = await response.blob();
//       const urlObject = URL.createObjectURL(blob);
//       const link = document.createElement("a");
//       link.href = urlObject;
//       link.download = "generated_avatar.png";
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//       URL.revokeObjectURL(urlObject);
//     } catch (error) {
//       console.error("Error downloading image:", error);
//       setError("Failed to download image. Please try again.");
//     }
//   };
  

//   const handleViewImage = () => setIsViewModalOpen(true);

//   const handleLocalDelete = async (statusId) => {
//     await handleDelete(statusId);
//     setAvatars(avatars.filter((avatar) => avatar.status_id !== statusId));
//     if (avatars.length === 1) {
//       setCurrentIndex(0);
//     } else if (currentIndex === avatars.length - 1) {
//       setCurrentIndex(currentIndex - 1);
//     }
//   };

//   return (
//     <div className="bg-white rounded-lg overflow-hidden shadow-md">
//       {avatars.length > 0 ? (
//         <>
//           <div className="relative w-full" style={{ paddingBottom: "100%" }}>
//             <img
//               src={currentAvatar.photo_url}
//               alt={`Generated Avatar ${currentIndex + 1}`}
//               className="absolute top-0 left-0 w-full h-full object-contain"
//             />
//             <div className="absolute top-1/2 left-0 transform -translate-y-1/2">
//               <IconButton
//                 onClick={handlePrev}
//                 icon={ChevronLeftIcon}
//                 label="Previous"
//                 color="bg-gray-800 bg-opacity-50 text-white hover:bg-opacity-75"
//               />
//             </div>
//             <div className="absolute top-1/2 right-0 transform -translate-y-1/2">
//               <IconButton
//                 onClick={handleNext}
//                 icon={ChevronRightIcon}
//                 label="Next"
//                 color="bg-gray-800 bg-opacity-50 text-white hover:bg-opacity-75"
//               />
//             </div>
//           </div>
//           <div className="p-4">
//             <p className="text-sm text-gray-600 mb-2">
//               Generated on:{" "}
//               {new Date(currentAvatar.created_at).toLocaleDateString()}
//             </p>
//             <div className="h-12 mb-4 overflow-hidden">
//               <p
//                 className="text-sm text-gray-600 line-clamp-2"
//                 title={currentAvatar.prompt}
//               >
//                 Prompt: {currentAvatar.prompt}
//               </p>
//             </div>
//             <div className="flex justify-between">
//               <IconButton
//                 onClick={() => handleDownload(currentAvatar.id)}
//                 icon={ArrowDownTrayIcon}
//                 label="Download"
//                 color="bg-blue-500 text-white hover:bg-blue-600"
//               />
//               <IconButton
//                 onClick={handleViewImage}
//                 icon={EyeIcon}
//                 label="View"
//                 color="bg-blue-500 text-white hover:bg-blue-600"
//               />
//               <IconButton
//                 onClick={() => onShop(currentAvatar)}
//                 icon={ShoppingBagIcon}
//                 label="Shop"
//                 color="bg-blue-500 text-white hover:bg-blue-600"
//               />
//               <IconButton
//                 onClick={() => handleLocalDelete(currentAvatar.status_id)}
//                 icon={TrashIcon}
//                 label="Delete"
//                 color="bg-red-500 text-white hover:bg-red-600"
//               />
//             </div>
//           </div>
//         </>
//       ) : (
//         <div className="p-4 text-center text-gray-600">
//           No avatars available.
//         </div>
//       )}
//       {isViewModalOpen && (
//         <div
//           className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
//           onClick={() => setIsViewModalOpen(false)}
//         >
//           <div
//             className="bg-white rounded-lg overflow-hidden flex items-center justify-center relative"
//             style={{
//               maxWidth: "calc(100vw - 2rem)",
//               maxHeight: "calc(100vh - 2rem)",
//               width: "auto",
//               height: "auto",
//             }}
//             onClick={(e) => e.stopPropagation()}
//           >
//             <div className="p-4 relative">
//               <img
//                 src={currentAvatar.photo_url}
//                 alt="Full size avatar"
//                 className="max-w-full max-h-full object-contain rounded"
//                 style={{
//                   maxWidth: "calc(100vw - 4rem)",
//                   maxHeight: "calc(100vh - 4rem)",
//                 }}
//               />
//               <button
//                 onClick={() => setIsViewModalOpen(false)}
//                 className="absolute top-2 right-2 p-1 bg-gray-800 text-white rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
//                 aria-label="Close"
//               >
//                 <XMarkIcon className="w-6 h-6" />
//               </button>
//               <button
//           onClick={(e) => {
//             e.stopPropagation();
//             handlePrev();
//           }}
//           className="absolute top-1/2 left-4 transform -translate-y-1/2 p-2 bg-gray-800 bg-opacity-50 text-white rounded-full hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
//           aria-label="Previous"
//         >
//           <ChevronLeftIcon className="w-6 h-6" />
//         </button>
//         <button
//           onClick={(e) => {
//             e.stopPropagation();
//             handleNext();
//           }}
//           className="absolute top-1/2 right-4 transform -translate-y-1/2 p-2 bg-gray-800 bg-opacity-50 text-white rounded-full hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
//           aria-label="Next"
//         >
//           <ChevronRightIcon className="w-6 h-6" />
//         </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AvatarDisplay;
