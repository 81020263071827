import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PhotoIcon, PencilSquareIcon, SparklesIcon, ArrowDownTrayIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { motion } from "framer-motion";
import animehead from "../assets/animehead.png";
import animefull from "../assets/animefull.png";
import cartoonhead from "../assets/cartoonhead.png";
import cartoonhalf from "../assets/cartoonhalf.png";
import cartoonfull from "../assets/cartoonfull.png";
import dhead from "../assets/dhead.png";
import dhalf from "../assets/dhalf.png";
import realhalf from "../assets/realhalf.png";
import realhead from "../assets/realhead.png";
import realfull from "../assets/realfull.png";



const avatarExamples = [
    { imageUrl: animehead, prompt: "Student close up" },
    { imageUrl: animefull, prompt: "Samurai" },
    { imageUrl: cartoonfull, prompt: "Outdoor Adventure" },
    { imageUrl: cartoonhalf, prompt: "Superhero" },
    { imageUrl: cartoonhead, prompt: "Friendly Teacher" },
    { imageUrl: dhalf, prompt: "American superhero" },
    { imageUrl: dhead, prompt: "Friendly Neighbour" },
    { imageUrl: realfull, prompt: "Outdoor Adventure" },
    { imageUrl: realhalf, prompt: "A man standing outdoors" },
    { imageUrl: realhead, prompt: "Professional Headshot" },
];

const LandingPage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % avatarExamples.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + avatarExamples.length) % avatarExamples.length);
  };

  return (
    <div className="bg-gray-50 text-gray-800">
      <header className="bg-white py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-12"
          >
            <h1 className="text-5xl font-bold mb-4 text-gray-900">
              Transform Yourself with One Image.
            </h1>
            <p className="text-xl mb-8 text-gray-600">
              Create avatars, visualize new looks, and unleash your imagination
            </p>
            <Link
              to="/register"
              className="inline-flex items-center px-6 py-3 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700 transition duration-300"
            >
              Start Creating <ArrowRightIcon className="ml-2 h-5 w-5" />
            </Link>
          </motion.div>
        </div>
      </header>

      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              title="Fun AI Avatars"
              description="Turn yourself into anime characters, superheroes, or fantastical creatures"
              imageUrl={cartoonhead}
            />
            <FeatureCard
              title="Stylish Prints"
              description="Create unique, personalized artwork for your home or as gifts"
              imageUrl={animefull}
            />
            <FeatureCard
              title="Try New Looks"
              description="Experiment with different hairstyles, outfits, and makeup before making a change"
              imageUrl={realhalf}
            />
          </div>
        </div>
      </section>


      <section id="how-it-works" className="py-20 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12 text-gray-900">
            Your Journey to a New You
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <StepCard
              icon={PhotoIcon}
              title="Upload Your Selfie"
              description="Begin with a clear photo of yourself. Our AI-powered platform supports various image formats for a seamless start."
            />
            <StepCard
              icon={PencilSquareIcon}
              title="Describe Your Vision"
              description="Let your imagination run wild! Type in a creative prompt describing your dream avatar. From fantasy realms to futuristic worlds, the possibilities are endless."
            />
            <StepCard
              icon={SparklesIcon}
              title="Watch the Magic Unfold"
              description="Hit 'Generate' and witness our advanced AI algorithms bring your vision to life. Your first masterpiece is on us, and each additional avatar is just $1.99!"
            />
            <StepCard
              icon={ArrowDownTrayIcon}
              title="Share Your New Self"
              description="Download your favorite creations in stunning high resolution. Perfect for profile pictures, social media, or just showing off your new looks!"
            />
          </div>
        </div>
      </section>

      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12 text-gray-900">
            Gallery
          </h2>
          <div className="max-w-4xl mx-auto relative">
            <div className="relative w-full" style={{ paddingBottom: "75%" }}>
              <motion.img
                key={currentImageIndex}
                src={avatarExamples[currentImageIndex].imageUrl}
                alt={`Example Avatar ${currentImageIndex + 1}`}
                className="absolute top-0 left-0 w-full h-full object-contain rounded-lg shadow-lg"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              />
            </div>
            <p className="text-xl text-gray-600 italic text-center mt-4">
              "{avatarExamples[currentImageIndex].prompt}"
            </p>
            <button
              onClick={prevImage}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white text-blue-600 p-2 rounded-full shadow-md hover:bg-gray-100"
            >
              &#10094;
            </button>
            <button
              onClick={nextImage}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white text-blue-600 p-2 rounded-full shadow-md hover:bg-gray-100"
            >
              &#10095;
            </button>
          </div>
        </div>
      </section>
      {/* bg-gradient-to-r from-blue-500 to-purple-600 */}
      <section className="py-20 text-center text-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl text-gray-900 font-bold mb-8">Ready to Reimagine Yourself?</h2>
          <Link
            to="/register"
            className="inline-block px-8 py-4 bg-white text-blue-600 font-bold rounded-md hover:bg-gray-100 transition duration-300"
          >
            Start Creating
          </Link>
        </div>
      </section>
    </div>
  );
};

const FeatureCard = ({ title, description, imageUrl }) => (
  <motion.div
    whileHover={{ scale: 1.03 }}
    className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-full"
  >
    <div className="relative overflow-hidden" style={{ height: "300px" }}>
      <img
        src={imageUrl}
        alt={title}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-full w-auto object-cover"
      />
    </div>
    <div className="p-6 flex-grow">
      <h3 className="text-xl font-bold text-gray-900 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </motion.div>
);

const StepCard = ({ icon: Icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.03 }}
    className="bg-white p-6 rounded-lg shadow-sm border border-gray-200"
  >
    <Icon className="w-12 h-12 text-blue-500 mb-4 mx-auto" />
    <h3 className="text-xl font-bold mb-2 text-center text-gray-900">{title}</h3>
    <p className="text-gray-600 text-center">{description}</p>
  </motion.div>
);

export default LandingPage;
