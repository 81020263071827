import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import config from "../utils/config";
import { Oval } from "react-loader-spinner";
import AvatarDisplay from "../components/AvatarDisplay";
import { motion, AnimatePresence } from "framer-motion";
import {
  CreditCardIcon,
  ExclamationCircleIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { paymentOptions } from "../assets/constants";
import OrderHistoryStatus from "../components/OrderHistoryStatus";

const base_backend = `${config.apiUrl}/api`;

const ProfilePage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [generatedAvatars, setGeneratedAvatars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [creditBalance, setCreditBalance] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [isPaymentExpanded, setIsPaymentExpanded] = useState(false);
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    if (user) {
      fetchUserData();
    }
  }, [user]);

  const fetchUserData = async () => {
    try {
      const userDataResponse = await fetch(`${base_backend}/user/user-data`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      const avatarsResponse = await fetch(`${base_backend}/avatars/avatars`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      const ordersResponse = await fetch(`${base_backend}/shop/user/orders`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (!ordersResponse.ok) {
        throw new Error("Failed to fetch orders");
      }

      const ordersData = await ordersResponse.json();
      setOrders(ordersData);

      if (!userDataResponse.ok || !avatarsResponse.ok) {
        throw new Error("Failed to fetch data");
      }

      const userData = await userDataResponse.json();
      const avatarsData = await avatarsResponse.json();

      setCreditBalance(userData.credit_balance);
      setGeneratedAvatars(avatarsData.avatars);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to load data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (statusId) => {
    try {
      const response = await fetch(
        `${base_backend}/avatars/avatars/${statusId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete avatars");
      }

      setGeneratedAvatars((prevAvatars) =>
        prevAvatars.filter((group) => group[0].status_id !== statusId)
      );
      setError("");
    } catch (error) {
      console.error("Error deleting avatars:", error);
      setError("Failed to delete avatars. Please try again.");
    }
  };

  const handlePayment = async (priceId) => {
    try {
      const response = await fetch(
        `${base_backend}/auth/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`,
          },
          body: JSON.stringify({ email: user.email, priceId }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        window.location.href = data;
      } else {
        throw new Error("Error creating checkout session");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      setError(
        `An error occurred while processing the payment. Please try again: ${error.message}`
      );
    }
  };

  const onPurchase = () => {
    handlePayment(selectedOption);
    setSelectedOption("");
  };

  const handleShopRedirect = (avatar) => {
    console.log(avatar);
    navigate("/shop", { state: { selectedAvatar: avatar } });
  };

  if (!user) {
    return (
      <div className="bg-gray-100 min-h-screen p-8 flex justify-center items-center">
        <p className="text-xl text-gray-600">
          Please log in to view your profile.
        </p>
      </div>
    );
  }
  return (
    <div className="bg-gray-100 min-h-screen p-4 sm:p-8">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-7xl mx-auto"
      >
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Your Profile</h2>
        
        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded"
            >
              <div className="flex items-center">
                <ExclamationCircleIcon className="w-5 h-5 mr-2" />
                <p>{error}</p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <Oval
              visible={true}
              height="80"
              width="80"
              color="#3B82F6"
              secondaryColor="#93C5FD"
              ariaLabel="oval-loading"
            />
          </div>
        ) : (
          <>
            {/* Avatars Section */}
            <div className="mb-8">
              <h3 className="text-2xl font-semibold mb-4 text-gray-700">Your Generated Avatars</h3>
              <div className="bg-white rounded-lg shadow-md p-4" style={{ height: '60vh', overflowY: 'auto' }}>
                {generatedAvatars.length === 0 ? (
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="text-center text-gray-600 p-4"
                  >
                    You haven't generated any avatars yet. Head to the generator to create your first avatar!
                  </motion.p>
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
                  >
                    <AnimatePresence>
                      {generatedAvatars.map((avatarGroup) => (
                        <motion.div
                          key={avatarGroup[0].status_id}
                          layout
                          initial={{ opacity: 0, scale: 0.9 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.9 }}
                          transition={{ duration: 0.3 }}
                        >
                          <AvatarDisplay 
                            avatarGroup={avatarGroup} 
                            setError={setError} 
                            handleDelete={handleDelete}
                            onShop={(avatar) => handleShopRedirect(avatar)}
                          />
                        </motion.div>
                      ))}
                    </AnimatePresence>
                  </motion.div>
                )}
              </div>
            </div>

            {/* Credit Balance and Order History Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Credit Balance */}
              <div>
                <h3 className="text-2xl font-semibold mb-4 text-gray-700">Credit Balance</h3>
                <div className="bg-white rounded-lg shadow-md p-4" style={{ height: '300px', overflowY: 'auto' }}>
                  <div className="flex items-center justify-evenly mb-4">
                    <div className="bg-blue-100 text-blue-800 px-4 py-2 rounded-full">
                      <CreditCardIcon className="w-5 h-5 inline mr-2" />
                      <span className="font-bold">{(creditBalance / 100).toFixed(0)}</span>
                    </div>
                    <button
                      onClick={() => setIsPaymentExpanded(!isPaymentExpanded)}
                      className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300 flex items-center"
                    >
                      <PlusIcon className="w-5 h-5 mr-2" />
                      {isPaymentExpanded ? "Hide" : "Purchase"}
                    </button>
                  </div>
                  <AnimatePresence>
                    {isPaymentExpanded && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <select
                          value={selectedOption}
                          onChange={(e) => setSelectedOption(e.target.value)}
                          className="w-full p-2 border border-gray-300 rounded-lg mb-2"
                        >
                          <option value="">Select Quantity...</option>
                          {paymentOptions.map((option) => (
                            <option key={option.id} value={option.id}>
                              {`${option.images} image${option.images > 1 ? "s" : ""} ($${option.price.toFixed(2)})`}
                            </option>
                          ))}
                        </select>
                        <button
                          onClick={onPurchase}
                          disabled={!selectedOption}
                          className="w-full px-6 py-2 bg-green-500 text-white font-bold rounded-lg hover:bg-green-600 transition duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
                        >
                          Purchase
                        </button>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>

              {/* Order History */}
              <div>
                <h3 className="text-2xl font-semibold mb-4 text-gray-700">Order History</h3>
                <div className="bg-white rounded-lg shadow-md p-4" style={{ height: '300px', overflowY: 'auto' }}>
                  <OrderHistoryStatus orders={orders} />
                </div>
              </div>
            </div>
          </>
        )}
      </motion.div>
    </div>
  );
  // return (
  //   <div className="bg-gray-100 min-h-screen p-4 sm:p-8">
  //     <motion.div
  //       initial={{ opacity: 0, y: -20 }}
  //       animate={{ opacity: 1, y: 0 }}
  //       transition={{ duration: 0.5 }}
  //       className="max-w-7xl mx-auto"
  //     >
  //       <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Your Profile</h2>
        
  //       <AnimatePresence>
  //         {error && (
  //           <motion.div
  //             initial={{ opacity: 0, y: -10 }}
  //             animate={{ opacity: 1, y: 0 }}
  //             exit={{ opacity: 0, y: -10 }}
  //             className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded"
  //           >
  //             <div className="flex items-center">
  //               <ExclamationCircleIcon className="w-5 h-5 mr-2" />
  //               <p>{error}</p>
  //             </div>
  //           </motion.div>
  //         )}
  //       </AnimatePresence>

  //       {isLoading ? (
  //         <div className="flex justify-center items-center h-64">
  //           <Oval
  //             visible={true}
  //             height="80"
  //             width="80"
  //             color="#3B82F6"
  //             secondaryColor="#93C5FD"
  //             ariaLabel="oval-loading"
  //           />
  //         </div>
  //       ) : (
  //         <>
  //           {/* Avatars Section */}
  //           <div className="mb-8">
  //             <h3 className="text-2xl font-semibold mb-4 text-gray-700">Your Generated Avatars</h3>
  //             <div className="bg-white rounded-lg shadow-md p-4" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
  //               {generatedAvatars.length === 0 ? (
  //                 <motion.p
  //                   initial={{ opacity: 0 }}
  //                   animate={{ opacity: 1 }}
  //                   className="text-center text-gray-600 p-4"
  //                 >
  //                   You haven't generated any avatars yet. Head to the generator to create your first avatar!
  //                 </motion.p>
  //               ) : (
  //                 <motion.div
  //                   initial={{ opacity: 0 }}
  //                   animate={{ opacity: 1 }}
  //                   transition={{ delay: 0.2 }}
  //                   className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
  //                 >
  //                   <AnimatePresence>
  //                     {generatedAvatars.map((avatarGroup) => (
  //                       <motion.div
  //                         key={avatarGroup[0].status_id}
  //                         layout
  //                         initial={{ opacity: 0, scale: 0.9 }}
  //                         animate={{ opacity: 1, scale: 1 }}
  //                         exit={{ opacity: 0, scale: 0.9 }}
  //                         transition={{ duration: 0.3 }}
  //                       >
  //                         <AvatarDisplay 
  //                           avatarGroup={avatarGroup} 
  //                           setError={setError} 
  //                           handleDelete={handleDelete}
  //                           onShop={(avatar) => handleShopRedirect(avatar)}
  //                         />
  //                       </motion.div>
  //                     ))}
  //                   </AnimatePresence>
  //                 </motion.div>
  //               )}
  //             </div>
  //           </div>

  //           {/* Credit Balance and Order History Section */}
  //           <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
  //             {/* Credit Balance */}
  //             <div className="lg:col-span-1">
  //               <h3 className="text-2xl font-semibold mb-4 text-gray-700">Credit Balance</h3>
  //               <div className="bg-white rounded-lg shadow-md p-4">
  //                 <div className="flex items-center justify-between mb-4">
  //                   <div className="bg-blue-100 text-blue-800 px-4 py-2 rounded-full">
  //                     <CreditCardIcon className="w-5 h-5 inline mr-2" />
  //                     <span className="font-bold">{(creditBalance / 100).toFixed(0)}</span>
  //                   </div>
  //                   <button
  //                     onClick={() => setIsPaymentExpanded(!isPaymentExpanded)}
  //                     className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300 flex items-center"
  //                   >
  //                     <PlusIcon className="w-5 h-5 mr-2" />
  //                     {isPaymentExpanded ? "Hide" : "Purchase"}
  //                   </button>
  //                 </div>
  //                 <AnimatePresence>
  //                   {isPaymentExpanded && (
  //                     <motion.div
  //                       initial={{ opacity: 0, height: 0 }}
  //                       animate={{ opacity: 1, height: "auto" }}
  //                       exit={{ opacity: 0, height: 0 }}
  //                       transition={{ duration: 0.3 }}
  //                     >
  //                       <select
  //                         value={selectedOption}
  //                         onChange={(e) => setSelectedOption(e.target.value)}
  //                         className="w-full p-2 border border-gray-300 rounded-lg mb-2"
  //                       >
  //                         <option value="">Select Quantity...</option>
  //                         {paymentOptions.map((option) => (
  //                           <option key={option.id} value={option.id}>
  //                             {`${option.images} image${option.images > 1 ? "s" : ""} ($${option.price.toFixed(2)})`}
  //                           </option>
  //                         ))}
  //                       </select>
  //                       <button
  //                         onClick={onPurchase}
  //                         disabled={!selectedOption}
  //                         className="w-full px-6 py-2 bg-green-500 text-white font-bold rounded-lg hover:bg-green-600 transition duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
  //                       >
  //                         Purchase
  //                       </button>
  //                     </motion.div>
  //                   )}
  //                 </AnimatePresence>
  //               </div>
  //             </div>

  //             {/* Order History */}
  //             <div className="lg:col-span-3">
  //               <h3 className="text-2xl font-semibold mb-4 text-gray-700">Order History</h3>
  //               <div className="bg-white rounded-lg shadow-md p-4">
  //                 <OrderHistoryStatus orders={orders} />
  //               </div>
  //             </div>
  //           </div>
  //         </>
  //       )}
  //     </motion.div>
  //   </div>
  // );
};

export default ProfilePage;
