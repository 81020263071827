import config from "../utils/config";

const base_backend = `${config.apiUrl}/api`
export const loginUser = async (data) => {
  console.log("BASE BACKEND", base_backend); 
  try {
    const response = await fetch(`${base_backend}/auth/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const responseData = await response.json();
      return {
        access_token: responseData.access_token,
        refresh_token: responseData.refresh_token,
        user_id: responseData.user_id,
        email: responseData.email,
        authenticated: responseData.authenticated,
      };
    } else {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Login failed');
    }
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

export const registerUser = async (data) => {
  try {
    const response = await fetch(`${base_backend}/auth/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const responseData = await response.json();
      return {
        access_token: responseData.access_token,
        refresh_token: responseData.refresh_token,
        user_id: responseData.user_id,
        email: responseData.email,
        authenticated: responseData.authenticated,
      };
    } else {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Registration failed');
    }
  } catch (error) {
    console.error('There was an error!', error);
    throw error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await fetch(
      `${base_backend}/auth/forgotpassword`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      }
    );
    if (response.ok) {
      const data = await response.json();
      console.log("PASSWORD RESET REQUEST SENT");
      console.log(data);
      return data;
    } else {
      throw new Error("Forgot Password request failed");
    }
  } catch (error) {
    console.error("There was an error!", error);
  }
};
