import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Login from "./components/user/Login";
import Register from "./components/user/Register";
import Navbar from "./components/Navbar";
import PrivateRoute from "./components/PrivateRoute";
import ForgotPassword from "./components/user/ForgotPassword";
import ResetPassword from "./components/user/ResetPassword";
import AvatarGenerator from "./pages/AvatarGenerator";
import EmailVerification from "./pages/EmailVerification";
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import ProfilePage from "./pages/ProfilePage";
import ShopPage from "./pages/ShopPage";
import AuthBanner from "./components/AuthBanner";
import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Navbar />
        <AuthBanner />
        <div className="content">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/verify" element={<EmailVerification />} />
            <Route
              path="/avigen"
              element={
                <PrivateRoute>
                  <AvatarGenerator />
                </PrivateRoute>
              }
            />
            <Route path="/shop" element={<ShopPage />} />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <ProfilePage />
                </PrivateRoute>
              }
            />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/resetpassword/:token" element={<ResetPassword />} />
          </Routes>
        </div>
      </div>
    </AuthProvider>
  );
}

export default App;