import React, { useState } from 'react';
import { paymentOptions } from '../assets/constants';
import config from '../utils/config';
import { useAuth } from '../context/AuthContext';

const base_backend = `${config.apiUrl}/api`;

const Payment = ({ setError }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const { user } = useAuth();

  const handlePayment = async (priceId) => {
    try {
      const response = await fetch(
        `${base_backend}/auth/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`,
          },
          body: JSON.stringify({ email: user.email, priceId }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        window.location.href = data;
      } else {
        throw new Error("Error creating checkout session");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      setError(`An error occurred while processing the payment. Please try again: ${error.message}`);
    }
  };

  const onPurchase = () => {
    handlePayment(selectedOption);
    setSelectedOption("");
  };

  return (
    <div className="mt-12">
      <h2 className="text-3xl font-bold mb-6">Purchase Additional Images</h2>
      <div className="flex flex-col items-center space-y-4">
        <select
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
          className="p-2 border border-gray-300 rounded"
        >
          <option value="">Select Quantity...</option>
          {paymentOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {`${option.images} image${option.images > 1 ? "s" : ""} ($${option.price.toFixed(2)})`}
            </option>
          ))}
        </select>
        <button
          onClick={onPurchase}
          disabled={!selectedOption}
          className="px-6 py-2 bg-green-500 text-white font-bold rounded hover:bg-green-600 transition duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          Purchase
        </button>
      </div>
    </div>
  );
};

export default Payment;
