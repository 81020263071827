import { useCallback, useMemo } from 'react';
import { useAuth } from "../context/AuthContext";
import config from "../utils/config";

const base_backend = `${config.apiUrl}/api`;

const usePhotoApi = () => {
  const { user, refreshToken } = useAuth();

  const getPhotos = useCallback(async () => {
    try {
      let response = await fetch(`${base_backend}/photos/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (response.status === 401) {
        const newToken = await refreshToken();
        if (newToken) {
          response = await fetch(`${base_backend}/photos/`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${newToken}`,
            },
          });
        } else {
          throw new Error("Unable to refresh token");
        }
      }

      if (!response.ok) {
        throw new Error("Failed to fetch photos");
      }

      return await response.json();
    } catch (error) {
      console.error("Error fetching photos:", error);
      throw error;
    }
  }, [user.accessToken, refreshToken]);

  const deletePhotoPack = useCallback(async (requestId) => {
    const makeRequest = async (token) => {
      const response = await fetch(
        `${base_backend}/photos/?requestId=${requestId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return response.json();
    };
  
    try {
      return await makeRequest(user.accessToken);
    } catch (error) {
      if (error.message.includes("status: 401")) {
        const newToken = await refreshToken();
        if (newToken) {
          return await makeRequest(newToken);
        } else {
          throw new Error("Failed to refresh token");
        }
      }
      console.error("Error deleting photo pack:", error);
      throw error;
    }
  }, [user.accessToken, refreshToken]);
  
  // const uploadPhotos = useCallback(async (files) => {
          // formData.append("userId", user.id);
      // formData.append("frontal_photo", files[0]);
      // formData.append("profile_photo", files[1]);
const uploadPhotos = useCallback(async (file, ageCategory, gender) => {
    const makeRequest = async (token) => {
      const formData = new FormData();
      formData.append("userId", user.id);
      console.log("Original file size:", file.size);
      formData.append("frontal_photo", file);
      formData.append("ageCategory", ageCategory);
      formData.append("gender", gender);
      const response = await fetch(`${base_backend}/photos/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      return response.json();
    };
  
    try {
      return await makeRequest(user.accessToken);
    } catch (error) {
      if (error.message.includes("status: 401")) {
        const newToken = await refreshToken();
        if (newToken) {
          return await makeRequest(newToken);
        } else {
          throw new Error("Failed to refresh token");
        }
      }
      console.error("Error uploading photo:", error);
      throw error;
    }
  }, [user.id, user.accessToken, refreshToken]);
  
  return useMemo(() => ({ uploadPhotos, getPhotos, deletePhotoPack }), [uploadPhotos, getPhotos, deletePhotoPack]);
};

export default usePhotoApi;
