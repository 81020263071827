import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import config from "../utils/config";
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

const base_backend = `${config.apiUrl}/api`;

const EmailVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('Verifying your email...');
  const [success, setSuccess] = useState(false);

  const { login } = useAuth();

  useEffect(() => {
    if (!token) {
      setMessage('Invalid verification attempt.');
      setLoading(false);
      return;
    }

    const verifyEmail = async () => {
      try {
        const response = await fetch(`${base_backend}/auth/verify_email?token=${token}`);
        const data = await response.json();
        if (response.ok) {
          login(data.access_token, data.refresh_token, data.user_id, data.email, data.authenticated);
          setSuccess(true);
          setMessage('Email verified successfully!');
          setTimeout(() => navigate('/avigen'), 3000);
        } else {
          setMessage(data.message || 'Email verification failed.');
          setSuccess(false);
        }
      } catch (error) {
        console.error(`An error occurred: ${error.message}`);
        setMessage(`An error occurred: ${error.message}`);
        setSuccess(false);
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [token, navigate, login]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        {loading ? (
          <div className="flex flex-col items-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
            <p className="mt-4 text-lg text-gray-600">Verifying your email...</p>
          </div>
        ) : (
          <div className="text-center">
            {success ? (
              <CheckCircleIcon className="mx-auto h-12 w-12 text-green-500" />
            ) : (
              <XCircleIcon className="mx-auto h-12 w-12 text-red-500" />
            )}
            <h2 className="mt-4 text-xl font-semibold text-gray-900">{message}</h2>
            {success && (
              <p className="mt-2 text-sm text-gray-600">
                Redirecting you to the app in a few seconds...
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailVerification;
