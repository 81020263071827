import animehead from "../assets/animehead.png";
import animefull from "../assets/animefull.png";
import cartoonhead from "../assets/cartoonhead.png";
import cartoonhalf from "../assets/cartoonhalf.png";
import cartoonfull from "../assets/cartoonfull.png";
import dhead from "../assets/dhead.png";
import dhalf from "../assets/dhalf.png";
import realhalf from "../assets/realhalf.png";
import realhead from "../assets/realhead.png";
import realfull from "../assets/realfull.png";
// import 3dfull from "../assets/3dfull.png";


const PROD_PRICE_IDS = {
  ONE_DOLLAR: "price_1PBnooCZJcfgkwLwUFHIUu8u",
  THREE_DOLLARS: "price_1PBnpfCZJcfgkwLwdSPhHUPX",
  FIVE_DOLLARS: "price_1PBnpaCZJcfgkwLw7R20H1oD",
  TEN_DOLLARS: "price_1PBnpcCZJcfgkwLwbC00UYdF",
};

// const DEV_PRICE_IDS = {
//   ONE_DOLLAR: "price_1P2M5GCZJcfgkwLwBk5OamN3",
//   THREE_DOLLARS: "price_1P6omnCZJcfgkwLwbFpEDA3M",
//   FIVE_DOLLARS: "price_1P6onZCZJcfgkwLweCvwNz2w",
//   TEN_DOLLARS: "price_1P6ooYCZJcfgkwLwtyXu5xhF",
// };

const NEW_DEV_PRICE_IDS = {
  ONE_DOLLAR: "price_1Pii6eCZJcfgkwLwjGnJXnQ7",
  TWO_DOLLARS: "price_1Pii9BCZJcfgkwLwJickl9pS",
  FOUR_DOLLARS: "price_1Pii9PCZJcfgkwLwqeAZx9Ca",
  SIX_DOLLARS: "price_1Pii9fCZJcfgkwLw9gooewxF",
}
export const PRICE_IDS = process.env.NODE_ENV === 'production'
  ? PROD_PRICE_IDS
  : NEW_DEV_PRICE_IDS;


export const paymentOptions = [
  { id: PRICE_IDS.ONE_DOLLAR, images: 3, price: 1.0 },
  { id: PRICE_IDS.TWO_DOLLARS, images: 7, price: 2.0 },
  { id: PRICE_IDS.FOUR_DOLLARS, images: 15, price: 4.0 },
  { id: PRICE_IDS.SIX_DOLLARS, images: 25, price: 6 },
];

export const avatarExamples = {
  Anime: [
    { photo_url: animehead, prompt: "Student close up" },
    { photo_url: animefull, prompt: "Samurai" },
  ],
  Cartoon: [
    { photo_url: cartoonfull, prompt: "Outdoor Adventure" },
    { photo_url: cartoonhalf, prompt: "Superhero" },
    { photo_url: cartoonhead, prompt: "Friendly Teacher" },
  ],
  Animation: [
    { photo_url: dhalf, prompt: "American superhero" },
    { photo_url: dhead, prompt: "Friendly Neighbour" },
  ],
  Photorealistic: [
    { photo_url: realfull, prompt: "Outdoor Adventure" },
    { photo_url: realhalf, prompt: "A man standing outdoors" },
    { photo_url: realhead, prompt: "Professional Headshot" },
  ],
};