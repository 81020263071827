import React, { useState, useEffect } from "react";
import { XMarkIcon, ShoppingCartIcon, TagIcon, SwatchIcon } from "@heroicons/react/24/outline";

const CustomizationModal = ({
  product,
  selectedAvatar,
  onClose,
  onAddToCart,
}) => {
  const [selectedSize, setSelectedSize] = useState(product.sizes[0]);
  const [selectedColor, setSelectedColor] = useState(product.colors[0]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [selectedSku, setSelectedSku] = useState(null);

  console.log("SELECTED SKU:", selectedSku);

  useEffect(() => {
    const sku = selectedColor.skus.find((sku) => sku.size === selectedSize);
    setSelectedSku(sku);
  }, [selectedColor, selectedSize]);

  const renderAvatarOverlay = () => {
    if (!selectedAvatar || !imageLoaded) return null;

    const [x, y, width, height] = product.rectangles;
    const style = {
      position: "absolute",
      left: `${x * 100}%`,
      top: `${y * 100}%`,
      width: `${width * 100}%`,
      height: `${height * 100}%`,
      objectFit: "contain",
    };

    return (
      <img src={selectedAvatar.photo_url} alt="Avatar overlay" style={style} />
    );
  };

  const handleAddToCart = () => {
    if (!selectedSku) {
      console.error("No SKU selected");
      return;
    }

    onAddToCart({
      ...product,
      color: selectedColor,
      size: selectedSize,
      selectedSku: selectedSku.id,
      price: selectedSku.price,
      avatar: selectedAvatar,
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-4xl flex flex-col max-h-[90vh] overflow-hidden shadow-xl">
        <div className="flex justify-between items-center p-6 border-b sticky top-0 bg-white z-10">
          <h2 className="text-2xl md:text-3xl font-bold truncate">
            {product.name}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 ml-2 flex-shrink-0 transition duration-300"
          >
            <XMarkIcon className="h-8 w-8" />
          </button>
        </div>
        <div className="p-6 overflow-y-auto flex-grow">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <div className="relative" style={{ width: '100%', paddingBottom: '100%' }}>
                <img
                  src={selectedColor.image}
                  alt={product.name}
                  className="absolute inset-0 w-full h-full object-contain rounded-lg shadow-md"
                  style={{ background: '#f0f0f0' }}
                  onLoad={() => setImageLoaded(true)}
                />
                {renderAvatarOverlay()}
              </div>
            </div>
            <div className="space-y-6">
              <div>
                <label className="block font-bold mb-2 text-lg text-gray-700 flex items-center">
                  <TagIcon className="h-6 w-6 mr-2 text-blue-600" />
                  Size
                </label>
                <select
                  value={selectedSize}
                  onChange={(e) => setSelectedSize(e.target.value)}
                  className="w-full p-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
                >
                  {product.sizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block font-bold mb-2 text-lg text-gray-700 flex items-center">
                  <SwatchIcon className="h-6 w-6 mr-2 text-blue-600" />
                  Color
                </label>
                <select
                  value={selectedColor.name}
                  onChange={(e) =>
                    setSelectedColor(
                      product.colors.find((c) => c.name === e.target.value)
                    )
                  }
                  className="w-full p-3 border rounded-lg text-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
                >
                  {product.colors.map((color) => (
                    <option key={color.name} value={color.name}>
                      {color.name}
                    </option>
                  ))}
                </select>
              </div>
              {selectedSku && (
                <div className="mb-4 bg-blue-50 p-4 rounded-lg">
                  <h3 className="text-xl font-bold text-blue-800">Price</h3>
                  <p className="text-2xl text-blue-600">${selectedSku.price.toFixed(2)}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="p-6 border-t sticky bottom-0 bg-white z-10">
          <button
            onClick={handleAddToCart}
            className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg text-xl font-semibold hover:bg-blue-700 transition duration-300 flex items-center justify-center"
          >
            <ShoppingCartIcon className="h-6 w-6 mr-2" />
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomizationModal;
