import React from "react";
import PropTypes from "prop-types";

const Input = ({ type, name, value, placeholder, onChange, label, options, isOtherSelected, tooltipId, tooltipContent, maxFiles, onRemoveFile, onClickFile }) => {
  const baseInputClasses = "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50";

  const renderInput = () => {
    switch (type) {
      case "text-dropdown":
        return (
          <div className="flex space-x-2">
            <select name={name} id={`${name}-select`} onChange={onChange} className={baseInputClasses} value={value}>
              <option value="">Select...</option>
              {options && options.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
              <option value="Custom">Custom</option>
            </select>
            {isOtherSelected && (
              <input type="text" name={`${name}-custom`} id={`${name}-custom`} value={value} placeholder={`Custom ${label}`} onChange={onChange} className={baseInputClasses} />
            )}
          </div>
        );
      case "radio":
        return (
          <div className="space-y-2">
            {options.map((option, index) => (
              <label key={index} className="inline-flex items-center">
                <input type="radio" name={name} value={option} checked={value === option} onChange={onChange} className="form-radio text-blue-600" />
                <span className="ml-2">{option}</span>
              </label>
            ))}
          </div>
        );
      case "file":
        return (
          <div>
            <input type="file" name={name} id={name} onChange={onChange} className="sr-only" multiple accept="image/*" max={maxFiles} />
            <label htmlFor={name} className="cursor-pointer bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Choose file(s)
            </label>
            <div className="mt-2 flex flex-wrap -m-1">
              {Array.isArray(value) && value.map((file, index) => (
                <div key={index} className="m-1 relative">
                  <img src={typeof file === 'string' ? file : URL.createObjectURL(file)} alt={``} className="h-20 w-20 object-cover rounded-md" onClick={() => onClickFile(file, index)} />
                  <button type="button" onClick={() => onRemoveFile(index)} className="absolute top-0 right-0 -mt-2 -mr-2 bg-red-500 text-white rounded-full p-1 text-xs">
                    &times;
                  </button>
                </div>
              ))}
            </div>
          </div>
        );
      default:
        return (
          <input
            type={type}
            name={name}
            id={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            className={baseInputClasses}
          />
        );
    }
  };

  return (
    <div>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700" data-tooltip-id={tooltipId} data-tooltip-content={tooltipContent}>
          {label}
        </label>
      )}
      {renderInput()}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  options: PropTypes.array,
  isOtherSelected: PropTypes.bool,
  tooltipId: PropTypes.string,
  tooltipContent: PropTypes.string,
  maxFiles: PropTypes.number,
  onRemoveFile: PropTypes.func,
  onClickFile: PropTypes.func,
};

export default Input;
