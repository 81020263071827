import React, { useState, useEffect } from "react";
import {
  XMarkIcon,
  ShoppingCartIcon,
  UserCircleIcon,
  ArrowRightIcon,
  PlusIcon,
  ChevronRightIcon,
  ChevronLeftIcon
} from "@heroicons/react/24/outline";
import { useAuth } from "../context/AuthContext";
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from "react-router-dom";
import config from "../utils/config";
import Sidebar from "../components/Sidebar";
import CartModal from "../components/CartModal";
import CustomizationModal from "../components/CustomizationModal";
import CheckoutModal from "../components/CheckoutModal";

const base_backend = `${config.apiUrl}/api`;

const prettifyCategory = (category) => {
  return category
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const ProductCard = ({ product, selectedAvatar, onCustomize }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const renderAvatarOverlay = () => {
    if (!selectedAvatar || !imageLoaded) return null;

    const [x, y, width, height] = product.rectangles;
    const overlayStyle = {
      position: "absolute",
      left: `${x * 100}%`,
      top: `${y * 100}%`,
      width: `${width * 100}%`,
      height: `${height * 100}%`,
    };

    return (
      <>
        <div
          style={overlayStyle}
        />
        {selectedAvatar && (
          <img
            src={selectedAvatar.photo_url}
            alt="Avatar overlay"
            style={{ ...overlayStyle, objectFit: "contain" }}
          />
        )}
      </>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col transition-transform duration-300 hover:shadow-lg hover:-translate-y-1">
      <div className="relative" style={{ width: '100%', paddingBottom: '100%' }}>
        <img
          src={product.image}
          alt={product.name}
          className="absolute inset-0 w-full h-full object-contain"
          style={{ background: '#f0f0f0' }} // Light grey background
          onLoad={() => setImageLoaded(true)}
        />
        {renderAvatarOverlay()}
      </div>
      <div className="p-4 flex-grow flex flex-col justify-between">
        <h3 className="font-bold text-lg text-gray-800">{product.name}</h3>
        <div>
          <p className="text-blue-600 font-semibold">
            From ${product.basePrice.toFixed(2)}
          </p>
          <button
            onClick={() => onCustomize(product)}
            className="mt-2 w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300 flex items-center justify-center"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Customize
          </button>
        </div>
      </div>
    </div>
  );
};

const IconButton = ({ onClick, icon: Icon, label, color }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    onClick={onClick}
    className={`group flex items-center p-2 rounded-full hover:rounded-lg transition-all duration-150 focus:outline-none focus:ring-2 focus:ring-opacity-50 ${color}`}
  >
    <Icon className="w-6 h-6 mr-0 group-hover:mr-2 transition-all duration-150" />
    <span className="w-0 overflow-hidden group-hover:w-auto transition-all duration-150 whitespace-nowrap">
      {label}
    </span>
  </motion.button>
);

const AvatarGroup = ({ avatarGroup, onSelect }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentAvatar = avatarGroup[currentIndex];

  const handlePrev = () => setCurrentIndex((prev) => 
    (prev > 0 ? prev - 1 : avatarGroup.length - 1)
  );
  
  const handleNext = () => setCurrentIndex((prev) => 
    (prev < avatarGroup.length - 1 ? prev + 1 : 0)
  );

  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-md">
      <div className="relative w-full" style={{ paddingBottom: "100%" }}>
        <img
          src={currentAvatar.photo_url}
          alt={`Avatar ${currentAvatar.id}`}
          className="absolute top-0 left-0 w-full h-full object-contain"
        />
        <div className="absolute top-1/2 left-0 transform -translate-y-1/2">
          <IconButton
            onClick={handlePrev}
            icon={ChevronLeftIcon}
            label="Previous"
            color="bg-gray-800 bg-opacity-50 text-white hover:bg-opacity-75"
          />
        </div>
        <div className="absolute top-1/2 right-0 transform -translate-y-1/2">
          <IconButton
            onClick={handleNext}
            icon={ChevronRightIcon}
            label="Next"
            color="bg-gray-800 bg-opacity-50 text-white hover:bg-opacity-75"
          />
        </div>
      </div>
      <div className="p-4">
        <button
          onClick={() => onSelect(currentAvatar)}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          Select
        </button>
      </div>
    </div>
  );
};

const AvatarSelectionModal = ({ avatars, onSelect, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-blue-600">Select Avatar</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition duration-300"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"
        >
          <AnimatePresence>
            {avatars.map((avatarGroup, index) => (
              <motion.div
                key={index}
                layout
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.3 }}
              >
                <AvatarGroup 
                  avatarGroup={avatarGroup} 
                  onSelect={onSelect}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};

const ShopPage = () => {
  const { user } = useAuth();
  const [activeCategory, setActiveCategory] = useState("T-Shirts");
  const [customizeProduct, setCustomizeProduct] = useState(null);
  const [cart, setCart] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (user) {
      fetchUserData();
      fetchCategories();
    }
  }, [user]);

  useEffect(() => {
    if (activeCategory && user) {
      fetchProducts(activeCategory);
    }
  }, [activeCategory, user]);

  useEffect(() => {
    if (location.state && location.state.selectedAvatar) {
      setSelectedAvatar(location.state.selectedAvatar);
    }
  }, [location.state]);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${base_backend}/shop/categories`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch categories");
      }

      const categoriesData = await response.json();
      const sortedCategories = categoriesData.sort((a, b) =>
        prettifyCategory(a).localeCompare(prettifyCategory(b))
      );
      setCategories(sortedCategories);
      setActiveCategory("T-Shirts");
    } catch (error) {
      console.error("Error fetching categories:", error);
      setError("Failed to load categories. Please try again later.");
    }
  };

  const fetchProducts = async (category) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${base_backend}/shop/catalog?category=${category}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }

      const productsData = await response.json();
      setProducts(productsData);
    } catch (error) {
      console.error("Error fetching products:", error);
      setError("Failed to load products. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserData = async () => {
    try {
      const avatarsResponse = await fetch(`${base_backend}/avatars/avatars`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (!avatarsResponse.ok) {
        throw new Error("Failed to fetch data");
      }

      const avatarsData = await avatarsResponse.json();
      setAvatars(avatarsData.avatars);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("Failed to load user data. Please try again later.");
    }
  };

  const handleCustomize = async (product) => {
    try {
      const response = await fetch(
        `${base_backend}/shop/product/${product.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch product details");
      }

      const productDetails = await response.json();
      setCustomizeProduct({ ...productDetails, selectedAvatar });
    } catch (error) {
      console.error("Error fetching product details:", error);
      setError("Failed to load product details. Please try again later.");
    }
  };

  const handleAddToCart = (customizedProduct) => {
    setCart([...cart, customizedProduct]);
    setCustomizeProduct(null);
  };

  const handleRemoveCartItem = (index) => {
    setCart(cart.filter((_, i) => i !== index));
  };


  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="relative flex">
      <Sidebar
        categories={categories}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        isOpen={isSidebarOpen}
        setIsOpen={setIsSidebarOpen}
      />
      <div className="p-4 sm:p-8">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6 gap-4">
        <div className="flex items-center gap-4 w-full sm:w-auto">
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300 flex items-center"
          >
            {isSidebarOpen ? (
              <ChevronLeftIcon className="h-5 w-5 mr-2" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 mr-2" />
            )}
            Categories
          </button>
          <h1 className="text-3xl font-bold">Shop</h1>
        </div>
        <div className="flex items-center gap-4 w-full sm:w-auto justify-end">
          <button
            onClick={() => setIsAvatarModalOpen(true)}
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300 flex items-center"
          >
            <UserCircleIcon className="h-5 w-5 mr-2" />
            {selectedAvatar ? "Change Avatar" : "Select Avatar"}
          </button>
          <button
            onClick={() => setIsCartModalOpen(true)}
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300 flex items-center"
          >
            <ShoppingCartIcon className="h-5 w-5 mr-2" />
            Cart ({cart.length})
          </button>
        </div>
      </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {products.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              selectedAvatar={selectedAvatar}
              onCustomize={handleCustomize}
            />
          ))}
        </div>
        {customizeProduct && (
          <CustomizationModal
            product={customizeProduct}
            selectedAvatar={selectedAvatar}
            onClose={() => setCustomizeProduct(null)}
            onAddToCart={handleAddToCart}
          />
        )}
        {isAvatarModalOpen && (
          <AvatarSelectionModal
            avatars={avatars}
            onSelect={(avatar) => {
              setSelectedAvatar(avatar);
              setIsAvatarModalOpen(false);
            }}
            onClose={() => setIsAvatarModalOpen(false)}
          />
        )}
        {cart.length > 0 && (
          <div className="mt-6 flex justify-between items-center bg-blue-100 p-4 rounded-lg">
            <span className="font-bold text-blue-800">
              Cart: {cart.length} item(s)
            </span>
            <button
              onClick={() => setIsCheckoutModalOpen(true)}
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300 flex items-center"
            >
              <ArrowRightIcon className="h-5 w-5 mr-2" />
              Checkout
            </button>
          </div>
        )}
        {isCartModalOpen && (
          <CartModal
            cart={cart}
            onClose={() => setIsCartModalOpen(false)}
            onCheckout={() => {
              setIsCartModalOpen(false);
              setIsCheckoutModalOpen(true);
            }}
            onRemoveItem={handleRemoveCartItem}
          />
        )}
        {isCheckoutModalOpen && (
          <CheckoutModal
            cart={cart}
            onClose={() => setIsCheckoutModalOpen(false)}
            onCheckoutComplete={() => {
              setCart([]);
              setIsCheckoutModalOpen(false);
            }}
            userEmail={user.email}
          />
        )}
      </div>
    </div>
  )
};

export default ShopPage;