import React from 'react';
import { UserCircleIcon, SparklesIcon, CurrencyDollarIcon, ShoppingCartIcon } from '@heroicons/react/24/outline';

const AboutPage = () => {
  return (
    <div className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-extrabold text-gray-900 text-center mb-8">About Vizify</h1>
        <p className="text-xl text-gray-600 mb-12 text-center">
          Welcome to Vizify, where your photos transform into amazing avatars! Our platform allows users to generate personalized avatars in various styles with ease.
        </p>
        
        <div className="space-y-12">
          <Section 
            title="Quick Start" 
            icon={SparklesIcon}
            content="Get started by uploading a photo of yourself. Choose from our range of styles: realistic, cartoon, anime, or 3D animation. Type out a prompt, hit generate, and receive three unique avatars."
          />

          <Section 
            title="Avatar Styles" 
            icon={UserCircleIcon}
            content={
              <>
                <p className="mb-4">Select from various styles to customize your avatar:</p>
                <ul className="list-disc list-inside mb-4 grid grid-cols-2 gap-2">
                  <li>Realistic</li>
                  <li>Cartoon</li>
                  <li>Anime</li>
                  <li>3D Animation</li>
                </ul>
                <p>Upload your photo, choose a style, and generate three avatars for each credit. You can create multiple avatars by using different prompts or styles.</p>
              </>
            }
          />

          <Section 
            title="Pricing" 
            icon={CurrencyDollarIcon}
            content={
              <>
                <p className="mb-4">Each credit gives you three photos for the same prompt. Purchase additional photos at the following rates:</p>
                <ul className="list-disc list-inside mb-4">
                  <li>3 photo for $1.00</li>
                  <li>7 photos for $2.00</li>
                  <li>15 photos for $4.00</li>
                  <li>25 photos for $6.00</li>
                </ul>
                <p>Choose the package that suits your needs and enjoy creating diverse avatars.</p>
              </>
            }
          />

          <Section 
            title="Print Your Avatars" 
            icon={ShoppingCartIcon}
            content="Connect with our drop shipping interface to get your avatars printed on various products. Personalize items like t-shirts, mugs, and more with your unique avatars."
          />
        </div>

        <p className="text-xl text-gray-600 mt-12 text-center">
          We hope you enjoy creating and sharing your unique avatars with Vizify!
        </p>
      </div>
    </div>
  );
};

const Section = ({ title, icon: Icon, content }) => (
  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
    <div className="px-4 py-5 sm:px-6 flex items-center">
      <Icon className="h-6 w-6 text-gray-400 mr-3" />
      <h2 className="text-2xl font-bold text-gray-900">{title}</h2>
    </div>
    <div className="border-t border-gray-200 px-4 py-5 sm:p-6 text-gray-600">
      {content}
    </div>
  </div>
);

export default AboutPage;
