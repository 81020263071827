import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { XMarkIcon, Bars3Icon } from '@heroicons/react/24/outline';
import logoImage from '../assets/logo3.png';

const Navbar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/', { replace: true });
  };

  const NavLink = ({ to, children, onClick }) => (
    <Link 
      to={to} 
      className="block text-gray-600 hover:text-blue-500 px-4 py-3 rounded-md text-base font-medium"
      onClick={onClick}
    >
      {children}
    </Link>
  );

  const closeMenu = () => setIsMenuOpen(false);

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link to="/">
                <img className="h-10 w-auto" src={logoImage} alt="Vizify Logo" />
              </Link>
            </div>
          </div>
          <div className="hidden md:block flex-grow">
            <div className="ml-10 flex items-center justify-end space-x-6">
              <NavLink to="/about">About</NavLink>
              <NavLink to="/contact">Contact</NavLink>
              {user ? (
                <>
                  <NavLink to="/avigen">Generator</NavLink>
                  <NavLink to="/profile">Profile</NavLink>
                  <NavLink to="/shop">Shop</NavLink>
                  <button 
                    onClick={handleLogout} 
                    className="text-gray-600 hover:text-blue-500 px-4 py-3 rounded-md text-base font-medium"
                  >
                    Logout
                  </button>
                </>
              ) : (
                <>
                  <NavLink to="/login">Login</NavLink>
                  <NavLink to="/register">Register</NavLink>
                </>
              )}
            </div>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-blue-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <NavLink to="/about" onClick={closeMenu}>About</NavLink>
            <NavLink to="/contact" onClick={closeMenu}>Contact</NavLink>
            {user ? (
              <>
                <NavLink to="/avigen" onClick={closeMenu}>Generator</NavLink>
                <NavLink to="/profile" onClick={closeMenu}>Profile</NavLink>
                <NavLink to="/shop" onClick={closeMenu}>Shop</NavLink>
                <button 
                  onClick={() => {
                    handleLogout();
                    closeMenu();
                  }} 
                  className="block w-full text-left text-gray-600 hover:text-blue-500 px-4 py-3 rounded-md text-base font-medium"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <NavLink to="/login" onClick={closeMenu}>Login</NavLink>
                <NavLink to="/register" onClick={closeMenu}>Register</NavLink>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
