import config from "../utils/config";

const base_backend = `${config.apiUrl}/api`;
export const setAccessToken = (token) => localStorage.setItem('accessToken', token);
export const setRefreshToken = (token) => localStorage.setItem('refreshToken', token);
export const getAccessToken = () => localStorage.getItem('accessToken');
export const getRefreshToken = () => localStorage.getItem('refreshToken');
export const removeAccessToken = () => localStorage.removeItem('accessToken');
export const removeRefreshToken = () => localStorage.removeItem('refreshToken');
export const refreshAccessToken = async (refreshToken) => {
    try {
      const response = await fetch(`${base_backend}/auth/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh_token: refreshToken }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Token refresh failed:', errorData);
        return null;
      }
  
      const data = await response.json();
      return data.access_token;
    } catch (error) {
      console.error('Error refreshing access token:', error);
      return null;
    }
  };
