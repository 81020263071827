import React from "react";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";

const prettifyCategory = (category) => {
  return category
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
const Sidebar = ({ categories, activeCategory, setActiveCategory, isOpen, setIsOpen }) => {
  console.log("ACTIVE CATEGORY: ", activeCategory);
  const handleCategoryClick = (category) => {
    console.log(category)
    setActiveCategory(category);
    setIsOpen(false);
  };

  return (
    <div
      className={`fixed inset-y-0 left-0 z-30 bg-gray-100 transition-all duration-300 ${
        isOpen ? "w-64" : "w-0"
      } flex flex-col shadow-lg overflow-hidden`}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-4 text-blue-600 hover:text-blue-800 self-end transition duration-300"
      >
        {isOpen ? (
          <ChevronLeftIcon className="h-6 w-6" />
        ) : (
          <ChevronRightIcon className="h-6 w-6" />
        )}
      </button>
      <div className="overflow-y-auto flex-grow px-4">
        <h2 className="text-xl font-bold mb-4 flex items-center">
          Categories
        </h2>
        <div className="space-y-2">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => handleCategoryClick(category)}
              className={`block w-full text-left p-2 rounded-lg transition duration-300 ${
                activeCategory === category
                  ? "bg-blue-600 text-white shadow-md"
                  : "hover:bg-gray-200 text-gray-700 hover:text-blue-600"
              } flex items-center`}
            >
              {prettifyCategory(category)}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
