import React from "react";
import { XMarkIcon, TrashIcon, ShoppingCartIcon, ShoppingBagIcon } from "@heroicons/react/24/outline";

const CartModal = ({ cart, onClose, onCheckout, onRemoveItem }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-4xl flex flex-col max-h-[90vh] overflow-hidden shadow-xl">
        <div className="flex justify-between items-center p-6 border-b sticky top-0 bg-white z-10">
          <h2 className="text-2xl md:text-3xl font-bold flex items-center">
            <ShoppingCartIcon className="h-8 w-8 mr-2 text-blue-600" />
            Your Cart
          </h2>
          <button 
            onClick={onClose} 
            className="text-gray-500 hover:text-gray-700 ml-2 flex-shrink-0 transition duration-300"
          >
            <XMarkIcon className="h-8 w-8" />
          </button>
        </div>
        <div className="p-6 overflow-y-auto flex-grow">
          {cart.length === 0 ? (
            <div className="text-center text-gray-500 py-12">
              <ShoppingBagIcon className="h-16 w-16 mx-auto mb-4 text-gray-400" />
              <p className="text-xl">Your cart is empty</p>
            </div>
          ) : (
            <ul className="space-y-6">
              {cart.map((item, index) => (
                <li key={index} className="flex items-center space-x-4 border-b pb-4 hover:bg-gray-50 transition duration-300 rounded-lg p-4">
                  <div className="w-24 h-24 relative overflow-hidden rounded-md shadow-sm">
                    <img src={item.color.image} alt={item.name} className="w-full h-full object-contain" />
                    {item.avatar && (
                      <div className="absolute inset-0">
                        <img
                          src={item.avatar.photo_url}
                          alt="Avatar"
                          className="absolute object-contain"
                          style={{
                            left: `${item.rectangles[0] * 100}%`,
                            top: `${item.rectangles[1] * 100}%`,
                            width: `${item.rectangles[2] * 100}%`,
                            height: `${item.rectangles[3] * 100}%`,
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex-grow">
                    <h3 className="font-bold text-lg text-blue-600">{item.name}</h3>
                    <p className="text-gray-600">Size: {item.size}</p>
                    <p className="text-gray-600">Color: {item.color.name}</p>
                    <p className="font-semibold text-blue-800">${item.price.toFixed(2)}</p>
                  </div>
                  <button
                    onClick={() => onRemoveItem(index)}
                    className="text-red-500 hover:text-red-700 transition duration-300"
                  >
                    <TrashIcon className="h-6 w-6" />
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="p-6 border-t sticky bottom-0 bg-white z-10 flex justify-between items-center">
          <p className="text-xl font-bold text-blue-800">
            Total: ${cart.reduce((total, item) => total + item.price, 0).toFixed(2)}
          </p>
          <button
            onClick={onCheckout}
            disabled={cart.length === 0}
            className="bg-blue-600 text-white py-3 px-6 rounded-lg text-xl font-semibold hover:bg-blue-700 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
          >
            <ShoppingBagIcon className="h-6 w-6 mr-2" />
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartModal;
