import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useAuth } from "../context/AuthContext";
import {
  XMarkIcon,
  UserIcon,
  EnvelopeIcon,
  PhoneIcon,
  MapPinIcon,
  GlobeAltIcon,
  BuildingOfficeIcon,
  CreditCardIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import config from "../utils/config";
import { countries, usStates } from "../utils/countrycodes";
const base_backend = `${config.apiUrl}/api`;
const stripePromise = loadStripe(
  "pk_test_51P14OiCZJcfgkwLw4moHiiKWueIcAKP43rM5UeH0X09VuL81X2fC8Gq4qE0gZkXZgCHCYW5O7ozeOCP67fVWLPev00RM4aWVfB"
);

const CheckoutForm = ({ cart, onClose, onCheckoutComplete, userEmail }) => {
  const stripe = useStripe();
  const { user } = useAuth();
  const elements = useElements();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(userEmail || "");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [shippingAddress, setShippingAddress] = useState({
    address: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
  });
  const [billingAddress, setBillingAddress] = useState({
    address: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
  });
  const [sameAsBilling, setSameAsBilling] = useState(true);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [selectedShipping, setSelectedShipping] = useState("");
  const [shippingCost, setShippingCost] = useState(0);
  const [productsTotal, setProductsTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [isFormComplete, setIsFormComplete] = useState(false);
  useEffect(() => {
    calculateProductsTotal();
  }, []);

  useEffect(() => {
    if (shippingAddress.country) {
      fetchShippingOptions(shippingAddress.country);
    }
  }, [shippingAddress.country]);

  useEffect(() => {
    const isShippingAddressComplete = Object.values(shippingAddress).every(value => value !== '');
    const isBillingAddressComplete = sameAsBilling || Object.values(billingAddress).every(value => value !== '');
    
    setIsFormComplete(
      firstName !== '' &&
      lastName !== '' &&
      email !== '' &&
      phoneNumber !== '' &&
      isShippingAddressComplete &&
      isBillingAddressComplete &&
      selectedShipping !== '' &&
      shippingCost > 0
    );
  }, [firstName, lastName, email, phoneNumber, shippingAddress, billingAddress, sameAsBilling, selectedShipping, shippingCost]);


  const calculateProductsTotal = () => {
    const cartTotal = cart.reduce((sum, item) => sum + item.price, 0);
    setProductsTotal(cartTotal);
    setTotal(cartTotal);
  };

  const fetchShippingOptions = async (countryCode) => {
    try {
      const response = await fetch(
        `${base_backend}/shop/shipping?country_code=${countryCode}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );
      const options = await response.json();
      console.log("OPTIONS", options);
      setShippingOptions(options);
      setSelectedShipping("");
    } catch (error) {
      console.error("Error fetching shipping options:", error);
      setError("Failed to fetch shipping options. Please try again.");
    }
  };

  const handleAddressChange = (type, e) => {
    const { name, value } = e.target;
    if (type === "shipping") {
      setShippingAddress((prev) => ({ ...prev, [name]: value }));
    } else {
      setBillingAddress((prev) => ({ ...prev, [name]: value }));
    }
  };

  const calculateShipping = async () => {
    if (!selectedShipping) {
      setError("Please select a shipping option");
      return;
    }

    try {
      const response = await fetch(
        `${base_backend}/shop/shipping/${selectedShipping.SHIPPING_ID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`,
          },
          body: JSON.stringify({
            state: shippingAddress.state,
            country_code: shippingAddress.country,
            items: cart.map((item) => ({
              catalog_sku: item.selectedSku,
              quantity: 1,
            })),
          }),
        }
      );
      const shippingCostData = await response.json();
      const shippingCost = parseFloat(shippingCostData);
      if (isNaN(shippingCost)) {
        throw new Error("Invalid shipping cost received from the server.");
      }
      setShippingCost(shippingCost);
      setTotal(productsTotal + shippingCost);
    } catch (error) {
      console.error("Error calculating shipping cost:", error);
      setError("Failed to calculate shipping cost. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setError(null);
    console.log("selectedShipping", selectedShipping);

    if (!stripe || !elements) {
      setError("Stripe hasn't loaded yet. Please try again.");
      setIsProcessing(false);
      return;
    }

    if (!selectedShipping) {
      setError("Please select a shipping option");
      setIsProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: `${firstName} ${lastName}`,
          phone: phoneNumber,
          address: {
            line1: sameAsBilling
              ? shippingAddress.address
              : billingAddress.address,
            city: sameAsBilling ? shippingAddress.city : billingAddress.city,
            state: sameAsBilling ? shippingAddress.state : billingAddress.state,
            postal_code: sameAsBilling
              ? shippingAddress.zipcode
              : billingAddress.zipcode,
            country: sameAsBilling
              ? shippingAddress.country
              : billingAddress.country,
          },
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      const response = await fetch(
        `${base_backend}/auth/shop/create-payment-intent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`,
          },
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            phone: phoneNumber,
            paymentMethodId: paymentMethod.id,
            shippingAddress: shippingAddress,
            billingAddress: sameAsBilling ? shippingAddress : billingAddress,
            shippingOption: selectedShipping
              ? selectedShipping.SHIPPING_NAME
              : null,
            items: cart.map((item) => ({
              catalog_sku: item.selectedSku,
              design_url: item.avatar ? item.avatar.photo_url : null,
              quantity: 1,
              size: item.size,
              color: item.color.name,
              price: item.basePrice,
            })),
            amount: Math.round(total * 100),
          }),
        }
      );

      const paymentIntentResult = await response.json();

      if (paymentIntentResult.error) {
        throw new Error(paymentIntentResult.error);
      }

      const { client_secret } = paymentIntentResult;

      const { error: confirmError } = await stripe.confirmCardPayment(
        client_secret
      );

      if (confirmError) {
        throw new Error(confirmError.message);
      }

      onCheckoutComplete();
    } catch (error) {
      setError(error.message);
    }

    setIsProcessing(false);
  };

  const inputClass =
    "w-full p-3 border rounded-lg bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200";
  const iconClass =
    "absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400";

  const renderAddressFields = (type) => {
    const address = type === "shipping" ? shippingAddress : billingAddress;
    const handleChange = (e) => handleAddressChange(type, e);

    return (
      <div className="space-y-4">
        <h3 className="font-bold text-xl text-blue-600 mb-4">
          {type === "shipping" ? "Shipping" : "Billing"} Address
        </h3>
        <div className="relative">
          <MapPinIcon className={`${iconClass} h-5 w-5`} />
          <input
            type="text"
            name="address"
            value={address.address}
            onChange={handleChange}
            placeholder="Address"
            required
            className={`${inputClass} pl-10`}
          />
        </div>
        <div className="relative">
          <BuildingOfficeIcon className={`${iconClass} h-5 w-5`} />
          <input
            type="text"
            name="city"
            value={address.city}
            onChange={handleChange}
            placeholder="City"
            required
            className={`${inputClass} pl-10`}
          />
        </div>
        <div className="relative">
          <GlobeAltIcon className={`${iconClass} h-5 w-5`} />
          <select
            name="country"
            value={address.country}
            onChange={handleChange}
            required
            className={`${inputClass} pl-10`}
          >
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        {address.country === "US" && (
          <div className="relative">
            <MapPinIcon className={`${iconClass} h-5 w-5`} />
            <select
              name="state"
              value={address.state}
              onChange={handleChange}
              required
              className={`${inputClass} pl-10`}
            >
              <option value="">Select State</option>
              {usStates.map((state) => (
                <option key={state.code} value={state.code}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="relative">
          <MapPinIcon className={`${iconClass} h-5 w-5`} />
          <input
            type="text"
            name="zipcode"
            value={address.zipcode}
            onChange={handleChange}
            placeholder="Zipcode"
            required
            className={`${inputClass} pl-10`}
          />
        </div>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="relative">
          <UserIcon className={`${iconClass} h-5 w-5`} />
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First Name"
            required
            className={`${inputClass} pl-10`}
          />
        </div>
        <div className="relative">
          <UserIcon className={`${iconClass} h-5 w-5`} />
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last Name"
            required
            className={`${inputClass} pl-10`}
          />
        </div>
      </div>

      <div className="relative">
        <EnvelopeIcon className={`${iconClass} h-5 w-5`} />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
          className={`${inputClass} pl-10`}
        />
      </div>

      <div className="relative">
        <PhoneIcon className={`${iconClass} h-5 w-5`} />
        <input
          type="tel"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Phone Number"
          required
          className={`${inputClass} pl-10`}
        />
      </div>

      {renderAddressFields("shipping")}
      {console.log(selectedShipping)}
      <div className="relative">
        <TruckIcon className={`${iconClass} h-5 w-5`} />
        <select
          value={selectedShipping ? selectedShipping.SHIPPING_ID : ""}
          onChange={(e) => {
            const selectedId = parseInt(e.target.value, 10);
            const selectedOption = shippingOptions.find(
              (option) => option.SHIPPING_ID === selectedId
            );
            console.log(e.target.value)
            console.log(shippingOptions)
            console.log(selectedOption)
            setSelectedShipping(selectedOption);
          }}
          required
          className={`${inputClass} pl-10`}
        >
          <option value="">Select a shipping option</option>
          {shippingOptions.map((option) => (
            <option key={option.SHIPPING_ID} value={option.SHIPPING_ID}>
              {option.SHIPPING_NAME}
            </option>
          ))}
        </select>
      </div>

      <button
        type="button"
        onClick={calculateShipping}
        className="w-full bg-blue-500 text-white py-3 px-4 rounded-lg hover:bg-blue-600 transition duration-300 flex items-center justify-center"
      >
        <TruckIcon className="h-5 w-5 mr-2" />
        Calculate Shipping
      </button>

      <div className="flex items-center">
        <input
          type="checkbox"
          checked={sameAsBilling}
          onChange={() => setSameAsBilling(!sameAsBilling)}
          className="mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
        />
        <label className="text-sm text-gray-700">
          Billing address same as shipping
        </label>
      </div>

      {!sameAsBilling && renderAddressFields("billing")}

      <div className="relative">
        <CreditCardIcon className={`${iconClass} h-5 w-5`} />
        <CardElement className={`${inputClass} pl-10`} />
      </div>

      <div className="bg-gray-100 p-4 rounded-lg space-y-2">
        <h3 className="font-bold text-lg text-blue-600">Order Summary</h3>
        <p>Products Total: ${productsTotal.toFixed(2)}</p>
        <p>Shipping Cost: ${shippingCost.toFixed(2)}</p>
        <p className="text-xl font-bold text-blue-600">
          Total: ${total.toFixed(2)}
        </p>
      </div>

      {error && (
        <div className="text-red-500 bg-red-100 p-3 rounded-lg">{error}</div>
      )}

      <button
        type="submit"
        disabled={isProcessing || !stripe || !isFormComplete}
        className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition duration-300 disabled:opacity-50 flex items-center justify-center"
      >
        {isProcessing ? (
          <>
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Processing...
          </>
        ) : (
          <>
            <CreditCardIcon className="h-5 w-5 mr-2" />
            Pay Now
          </>
        )}
      </button>
    </form>
  );
};

const CheckoutModal = ({ cart, onClose, onCheckoutComplete, userEmail }) => {
  return (
    <div className="fixed inset-0 bg-white z-50 overflow-y-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">Checkout</h1>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-gray-50 p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4">Your Cart</h2>
            {cart.map((item, index) => (
              <div
                key={index}
                className="flex items-center space-x-4 mb-4 border-b pb-4"
              >
                <div className="w-20 h-20 relative">
                  <img
                    src={item.color.image}
                    alt={item.name}
                    className="w-full h-full object-contain"
                  />
                  {item.avatar && (
                    <img
                      src={item.avatar.photo_url}
                      alt="Avatar overlay"
                      className="absolute inset-0 w-full h-full object-contain"
                      style={{
                        left: `${item.rectangles[0] * 100}%`,
                        top: `${item.rectangles[1] * 100}%`,
                        width: `${item.rectangles[2] * 100}%`,
                        height: `${item.rectangles[3] * 100}%`,
                      }}
                    />
                  )}
                </div>
                <div className="flex-grow">
                  <h3 className="font-bold">{item.name}</h3>
                  <p className="text-gray-600">Size: {item.size}</p>
                  <p className="text-gray-600">Color: {item.color.name}</p>
                  <p className="font-semibold">${item.price.toFixed(2)}</p>
                </div>
              </div>
            ))}
          </div>
          <div>
            <Elements stripe={stripePromise}>
              <CheckoutForm
                cart={cart}
                onClose={onClose}
                onCheckoutComplete={onCheckoutComplete}
                userEmail={userEmail}
              />
            </Elements>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutModal;
