import React, { useState, useEffect, useCallback } from 'react';
import usePhotoApi from '../services/photoService';
import { CheckCircleIcon, TrashIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

const ManagePhotosComponent = ({
  refreshPhotos,
  onSelect,
  userPhotosRequestId,
  showDeleteButton = true,
  isProfilePage = false,
}) => {
  const { getPhotos, deletePhotoPack } = usePhotoApi();
  const [photos, setPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const fetchPhotos = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getPhotos();
      setPhotos(response);
    } catch (error) {
      console.error("Failed to fetch photos:", error);
      setError("Failed to load photos. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [getPhotos]);

  useEffect(() => {
    fetchPhotos();
  }, [fetchPhotos, refreshPhotos, userPhotosRequestId]);

  const handleDelete = async (requestId) => {
    if (window.confirm('Are you sure you want to delete this photo pack?')) {
      try {
        await deletePhotoPack(requestId);
        setPhotos(photos.filter((photo) => photo.requestId !== requestId));
      } catch (error) {
        console.error("Failed to delete photo pack:", error);
        setError("Failed to delete photo. Please try again.");
      }
    }
  };

  const handleSelect = (photo) => {
    console.log("Selected photo:", photo.requestId);
    if(!onSelect) return;
    onSelect(photo.requestId);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 py-4 bg-red-100 rounded-lg">
        <ExclamationCircleIcon className="h-8 w-8 mx-auto mb-2" />
        {error}
      </div>
    );
  }
  
  return (
    <div className="space-y-6">
      {photos.length === 0 ? (
        <p className="text-center text-gray-600 py-8 bg-gray-100 rounded-lg">No photos uploaded yet. Upload some photos to get started!</p>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
          {photos.map((photo) => (
            <div
              key={photo.requestId}
              className={`relative rounded-lg shadow-md overflow-hidden transition-all duration-300 ${
                userPhotosRequestId === photo.requestId ? 'ring-2 ring-blue-500' : ''
              }`}
            >
              <div className="aspect-w-1 aspect-h-1">
                <img
                  src={photo.frontalPhotoUrl}
                  alt={``}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 flex flex-col items-center justify-center space-y-2">
                {onSelect && (
                  <button
                    type="button"
                    onClick={() => handleSelect(photo)}
                    className="p-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors duration-300"
                    aria-label="Select photo"
                  >
                    <CheckCircleIcon className="w-6 h-6" />
                  </button>
                )}
                {showDeleteButton && (
                  <button
                    type="button"
                    onClick={() => handleDelete(photo.requestId)}
                    className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors duration-300"
                    aria-label="Delete photo"
                  >
                    <TrashIcon className="w-6 h-6" />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ManagePhotosComponent;
