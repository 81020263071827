import React, { useState } from 'react';
import config from '../utils/config';
import { useAuth } from '../context/AuthContext';
import OrderStatusModal from './OrderStatusModal';

const base_backend = `${config.apiUrl}/api`;

const OrderHistoryStatus = ({orders}) => {
  const { user } = useAuth();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchOrderStatus = async (orderId) => {
    try {
      const response = await fetch(`${base_backend}/shop/user/order/${orderId}/status`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      const data = await response.json();
      console.log("DATA:", data);
      setSelectedOrder({
        order_id: data.order_id,
        order_statis: data.customcat_status,
        order_date: new Date().toISOString(), // You might want to add this to your backend response
        order_total: data.order_total, // You might want to add this to your backend response
        customer_name: data.customer_name, // Add these fields in your backend response if available
        customer_address: data.customer_address,
        shipments: data.shipments,
        line_items: data.line_items
      });
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching order status:', error);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  console.log("ORDER: ", orders);
  return (
    <div className="container mx-auto p-4">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {orders.map((order) => (
              <tr key={order.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{order.id}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatDate(order.created_at)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${order.total_amount.toFixed(2)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.status}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    onClick={() => fetchOrderStatus(order.id)}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <OrderStatusModal 
        order={selectedOrder} 
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedOrder(null);
        }}
      />
    </div>
  );
};

export default OrderHistoryStatus;
