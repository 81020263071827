import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const CollapsibleSidebar = ({ children, actions, title, isOpen, setIsOpen, position = 'left' }) => {

  const toggleSidebar = () => setIsOpen(!isOpen);

  const OpenChevronIcon = position === 'left' ? ChevronLeftIcon : ChevronRightIcon;
  const ClosedChevronIcon = position === 'left' ? ChevronRightIcon : ChevronLeftIcon;

  return (
    <div className={`fixed ${position}-0 top-0 h-full z-30 transition-all duration-300 ${isOpen ? 'w-72' : 'w-0'}`}>
      <div className={`relative h-full bg-white shadow-md flex flex-col ${isOpen ? 'w-72' : 'w-0'} overflow-hidden transition-all duration-300`}>
        {isOpen && (
          <>
            <div className="flex items-center justify-between p-4 border-b">
              <h3 className="text-xl font-bold text-gray-800">{title}</h3>
              <button
                onClick={toggleSidebar}
                className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
              >
                <OpenChevronIcon className="w-6 h-6" />
              </button>
            </div>
            <div className="flex-grow overflow-y-auto p-4">
              {children}
            </div>
            <div className="p-4 border-t">
              {actions.map((action, index) => (
                <button
                  key={index}
                  onClick={action.onClick}
                  className={`
                    w-full px-4 py-2 mb-2 last:mb-0 flex items-center justify-center
                    text-white rounded-md transition-all duration-200
                    focus:outline-none focus:ring-2 focus:ring-offset-2
                    ${action.disabled || action.loading
                      ? 'bg-gray-400 cursor-not-allowed' 
                      : `${action.className} hover:opacity-90 focus:ring-opacity-50`}
                  `}
                  disabled={action.disabled || action.loading}
                >
                  {action.loading ? (
                    <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                  ) : action.icon && (
                    <action.icon className="w-5 h-5 mr-2" />
                  )}
                  {action.loading ? 'Processing...' : action.label}
                </button>
              ))}
            </div>
          </>
        )}
      </div>
      {!isOpen && (
        <button
          onClick={toggleSidebar}
          className={`absolute top-4 ${position}-0 p-2 bg-white rounded-${position === 'left' ? 'r' : 'l'}-md shadow-md hover:bg-gray-100 transition-colors duration-200`}
        >
          <ClosedChevronIcon className="w-6 h-6" />
        </button>
      )}
    </div>
  );
};

export default CollapsibleSidebar;
// import React, { useState, useEffect } from 'react';
// import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

// const CollapsibleSidebar = ({ children, actions, title, position = 'left', creditBalance }) => {
//   const [isOpen, setIsOpen] = useState(true);
//   const [isVisible, setIsVisible] = useState(false);
//   const toggleSidebar = () => setIsOpen(!isOpen);

//   const OpenChevronIcon = position === 'left' ? ChevronLeftIcon : ChevronRightIcon;
//   const ClosedChevronIcon = position === 'left' ? ChevronRightIcon : ChevronLeftIcon;

//   useEffect(() => {
//     setIsOpen(creditBalance === 0);
//   }, [creditBalance]);

//   return (
//     <div className={`fixed ${position}-0 top-0 h-full z-30 transition-all duration-300 ${isOpen ? 'w-72' : 'w-0'}`}>
//       <div className={`relative h-full bg-white shadow-md flex flex-col ${isOpen ? 'w-72' : 'w-0'} overflow-hidden transition-all duration-300`}>
//         {isOpen && (
//           <>
//             <div className="flex items-center justify-evenly p-4 border-b">
//               {position === 'left' ? (
//                 <>
//                   <h3 className="text-xl font-bold text-gray-800">{title}</h3>
//                   <button
//                     onClick={toggleSidebar}
//                     className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
//                   >
//                     <OpenChevronIcon className="w-6 h-6" />
//                   </button>
//                 </>
//               ) : (
//                 <>
//                   <button
//                     onClick={toggleSidebar}
//                     className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
//                   >
//                     <OpenChevronIcon className="w-6 h-6" />
//                   </button>
//                   <h3 className="text-xl font-bold text-gray-800">{title}</h3>
//                 </>
//               )}
//             </div>
//             <div className="flex-grow overflow-y-auto p-4">
//               {children}
//             </div>
//             <div className="p-4 border-t">
//               {actions.map((action, index) => (
//                 <button
//                   key={index}
//                   onClick={action.onClick}
//                   className={`
//                     w-full px-4 py-2 mb-2 last:mb-0 flex items-center justify-center
//                     text-white rounded-md transition-all duration-200
//                     focus:outline-none focus:ring-2 focus:ring-offset-2
//                     ${action.disabled || action.loading
//                       ? 'bg-gray-400 cursor-not-allowed' 
//                       : `${action.className} hover:opacity-90 focus:ring-opacity-50`}
//                   `}
//                   disabled={action.disabled || action.loading}
//                 >
//                   {action.loading ? (
//                     <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
//                       <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
//                       <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
//                     </svg>
//                   ) : action.icon && (
//                     <action.icon className="w-5 h-5 mr-2" />
//                   )}
//                   {action.loading ? 'Processing...' : action.label}
//                 </button>
//               ))}
//             </div>
//           </>
//         )}
//       </div>
//       {!isOpen && (
//         <button
//           onClick={toggleSidebar}
//           className={`absolute top-4 ${position}-0 p-2 bg-white rounded-${position === 'left' ? 'r' : 'l'}-md shadow-md hover:bg-gray-100 transition-colors duration-200`}
//         >
//           <ClosedChevronIcon className="w-6 h-6" />
//         </button>
//       )}
//     </div>
//   );
// };

// export default CollapsibleSidebar;
